@import 'styles/variables';
@import 'styles/mixins';

.linked-account {
  max-width: 700px;
  margin: 0 auto;
  padding-top: 10px;
  position: relative;
  min-height: 800px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 60px;

  &-btnBack {
    padding: 0;
    background-color: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: $primary-color;
    margin-bottom: 20px;
    & img {
      width: 18px;
      height: 18px;
      position: relative;
      bottom: 2px;
    }
  }
  &-title {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.94px;
    line-height: 36px;
    margin-bottom: 24px;
  }
}
