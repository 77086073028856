@import 'styles/variables';

.custom-modal-consent-capture {
  max-width: 800px;

  .modal-content {
    .modal-view {
      padding: 0 !important;

      .modal-item {
        padding: 0 !important;
      }
    }
  }
}

.custom-modal-consent-capture-container {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  .custom-modal-consent-capture-title {
    display: flex;
    align-items: center;
    margin-top: 30px;
    padding-bottom: 16px;
    font-weight: bold;
    font-size: 22px;
  }

  .custom-modal-consent-capture-subtitle {
    padding-bottom: 16px;
    font-weight: bold;
    font-size: 18px;
    margin-top: 20px;
    span {
      color: $primary-color;
    }
  }

  .custom-modal-consent-capture-desc {
    font-size: 14px;
    letter-spacing: -0.35px;
    line-height: normal;
  }
  .custom-button {
    display: block;
    min-width: 200px;
    margin: 0 auto;
    margin-top: 60px;
  }
  .terms-conditions {
    font-family: Karla, sans-serif !important;
    font-size: 10px;
    letter-spacing: -0.5px;
    line-height: 14px;
    // margin: 20px 0;
    margin-top: 1.5rem;
    margin-bottom: 20px;

    .btn-text {
      color: $primary-color;
      cursor: pointer;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    margin-top: 40px;
  }
}
