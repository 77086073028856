#pre-loader {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  margin-top: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden !important;
  right: 0px;
  z-index: 999999;

  .group-loader {
    text-align: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    z-index: 99;
    margin: 0 auto;
    min-height: calc(100vh - 300px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .creating-plaid {
      margin-top: 50px !important;
      font-size: 16px;
      letter-spacing: -0.5px;
      line-height: 19px;
    }
    .almost-detail {
      color: #c9c9c9;
    }
    .link-more-account-btn {
      margin-top: 100px;
    }
  }
}
.loader-wrapper {
  .text-header {
    text-align: center;
    padding-top: 100px;
    font-weight: bold;
    font-size: 18px;
    line-height: 19px;
  }
}
