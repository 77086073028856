@import 'styles/variables';
@import 'styles/mixins';

.spent-by-merchant {
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  min-height: 600px;
  margin: 0 auto;
  padding: 0 20px;
  padding-top: 20px;
  margin-bottom: 60px;
  position: relative;

  &-btnBack {
    padding: 0;
    background-color: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: $primary-color;
    margin-bottom: 20px;
    & img {
      width: 18px;
      height: 18px;
      position: relative;
      bottom: 2px;
    }
  }

  &-header {
    &-title {
      font-size: 30px;
      font-weight: bold;
      letter-spacing: -0.94px;
      line-height: 36px;
      margin-bottom: 24px;
    }
    &-highlight {
      color: $primary-color;
    }
  }

  .spent-by-merchant--info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    margin-bottom: 20px;
    img {
      width: 80px;
      border-radius: 12px;
    }
    .spent-by-merchant--name {
      div {
        &:first-child {
          font-weight: bold;
          font-size: 22px;
        }
      }
    }
  }

  .spent-by-merchant--title {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: -0.94px;
    line-height: 36px;
    margin-bottom: 24px;
  }

  .spent-by-merchant--card-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .spent-by-merchant--card-name {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      font-size: 18px;
      img {
        width: 90px;
      }
    }
    .spent-by-merchant--card-reward {
      div {
        &:first-child {
          font-weight: bold;
          font-size: 24px;
        }
      }
    }
  }

  .spent-by-merchant--earned {
    padding: 16px;
    background-color: #f4f6ff;
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 20px;

    .spent-by-merchant--tag {
      background-color: #ffd551;
      width: fit-content;
      font-size: 14px;
      font-weight: bold;
      padding: 4px 14px;
      border-radius: 15px;
      margin-bottom: 16px;
    }
    .spent-by-merchant--earned-text {
      span {
        color: #000;
        font-weight: bold;
      }
    }
    .spent-by-merchant--potential-card {
      margin-top: 20px;
      gap: 12px;
      img {
        width: 60px;
      }
      div {
        color: $primary-color;
      }
    }
  }

  .spent-by-merchant--transaction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $border-gray;

    .spent-by-merchant--transaction-info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;

      img {
        width: 60px;
      }
      .spent-by-merchant--transaction-info-name {
        div {
          &:first-child {
            font-size: 18px;
          }
          font-size: 14px;
        }
      }
    }

    .spent-by-merchant--transaction-amount {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .btn-load-more {
    padding: 8px 12px;
    width: fit-content;
    border: 1px solid #000;
    border-radius: 30px;
    margin: 0 auto;
    margin-top: 30px;
    cursor: pointer;
  }
}
