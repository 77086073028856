@import 'styles/variables';
@import 'styles/mixins';

.modal-update-plan {
  .modal-body {
    padding: 0;
  }
}
.update-plan-container {
  padding-top: 24px;

  @include for-size(phone-only) {
    padding: 0;
  }

  .update-plan-title {
    font-size: 28px;
    letter-spacing: -1px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 24px;
    font-weight: bold;

    span {
      color: $primary-color;
    }
  }

  .update-plan-subtitle {
    font-size: 20px;
    text-align: center;
  }

  .update-plan-plans {
    // height: 78px;
    // width: 330px;
    // border: 1px solid #cccccc;
    // border-radius: 53px;
    // background-color: #eeeeee;
    // margin: 0 auto;
    // padding: 2px;

    // display: flex;
    // justify-content: space-between;
    // align-items: center;

    // @include for-size(phone-only) {
    //   width: 100%;
    // }

    .plan-item-container {
      // background-color: #eeeeee;
      // height: 72px;
      // width: 50%;
      width: max-content;
      border-radius: 53px;
      cursor: pointer;

      display: flex;
      justify-content: center;
      // align-items: center;
      align-items: flex-start;

      .plan-name {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: -1.2px;
        line-height: 28px;
        text-align: center;
        display: inline-flex;

        margin-left: 20px;

        flex-direction: column;
        h3 {
          display: flex;
          align-items: center;
          width: max-content;
        }
        .discount-percentage {
          // padding-left: 16px;
          // padding-right: 16px;
          padding: 5px 16px;
          // height: 19px;
          width: fit-content;
          border-radius: 100px;
          border: 1px solid #ffd551;
          margin-top: 4px;
          margin-left: 10px;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: -0.5px;
          line-height: 19px;
          text-align: center;
          // margin: 0 auto;

          // display: flex;
          justify-content: center;
          align-items: center;

          background-color: #ffd551;
        }
      }

      &:nth-child(n + 1) {
        margin-top: 30px;
      }
    }

    .plan-item-container.selected {
      // background-color: $primary-color;
      // .plan-name {
      //   color: white;
      // }

      .plan-radio > span {
        background-color: #000;
        width: 100%;
        border-radius: 100px;
        margin: 5px;
      }
    }

    .plan-radio {
      display: flex;
      width: 30px;
      height: 30px;
      background: white;
      border: 2px solid #000;
      border-radius: 1000px;
      position: relative;
    }
  }

  .plan-details {
    // font-size: 48px;
    font-size: 1.75rem;
    font-weight: bold;
    // letter-spacing: -2.4px;
    // line-height: 56px;
    letter-spacing: 0px;
    text-align: center;
    // margin-top: 16px;

    .plan-original-price {
      color: grey;
      text-decoration: line-through;
    }
    .plan-original-price.isMonth {
      text-decoration: none;
      display: none;
    }

    .plan-unit {
      font-weight: 300;
    }

    &.isMonth {
      font-weight: 300;
    }
  }

  .update-plan-plans {
    margin-left: 55px;
  }

  .update-plan-btn-container {
    margin-top: 80px;
    text-align: center;

    .update-plan-text-promote {
      font-size: 16px;
      margin-bottom: 20px;
      span {
        color: $primary-color;
        font-weight: bold;
      }
    }

    .update-plan-submit-button {
      height: 60px;
      min-width: 300px;
      border-radius: 53px;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: -1.2px;

      @include for-size(phone-only) {
        width: 100%;
      }
    }
  }
}
