@import 'styles/variables';
@import 'styles/mixins';

.linked-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  border-radius: 12px;
  border: 1px solid $border-gray;
  padding: 16px;
  margin-bottom: 16px;
  position: relative;

  &--exclamation {
    position: absolute;
    width: 30px;
    height: 30px;
    border: 1px solid $border-gray;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -15px;
    top: -15px;
    img {
      width: 100%;
      height: 80%;
      object-fit: contain;
    }
  }

  &--info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    img {
      max-width: 120px;
      height: 65px;
      object-fit: contain;
      border-radius: 5px;
    }
  }

  &--action {
    display: flex;
    align-items: center;
    gap: 12px;

    img {
      width: 30px;
    }
    div {
      color: $primary-color;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
