@import 'styles/mixins';
@import 'styles/variables';

.redeem-history {
  max-width: 760px;
  min-height: calc(100vh - 86px);

  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;

  @include for-size(phone-only) {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .redeem-history-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;

    @include for-size(phone-only) {
      font-size: 18px;
      letter-spacing: -0.35px;
    }
  }

  .redeem-history-table-container {
    .redeem-history-table-action {
      color: $primary-color;
      cursor: pointer;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.redeem-history-btnBack {
  padding: 0;
  background-color: transparent;
  border: none;
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: bold;
  color: $primary-color;

  & img {
    width: 18px;
    height: 18px;
    position: relative;
    bottom: 2px;
  }
}
