@import 'styles/variables';

.modal-exist-account {
  padding-top: 30px;
  .modal-exist-account--title {
    font-weight: bold;
    font-size: 30px;
    letter-spacing: -1.25px;
    line-height: 36px;
    text-align: left;
    color: black;
    margin-bottom: 20px;
    text-align: center;
  }

  .modal-exist-account--text {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .modal-exist-account--btn {
    border: 2px solid $primary-color;
    min-width: 200px;
    height: 50px;
    display: block;
    margin: 0 auto;
    background-color: white;
    border-radius: 30px;
    color: $primary-color;
    font-weight: bold;
  }
}
