@use 'styles/variables' as appVar;

.modal-login-account {
  .title-container {
    margin-top: 32px;
    .title {
      font-weight: bold;
      font-size: 30px;
      letter-spacing: -1.25px;
      line-height: 36px;
      text-align: left;
      color: black;
    }
    .subTitle {
      font-size: 16px;
      letter-spacing: -0.67px;
      color: black;
      .text-link {
        text-decoration: underline;
        color: appVar.$primary-color;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  .form-container-stp1 {
    .notice-text {
      color: black;
      font-size: 16px;
      letter-spacing: -0.67px;
    }
  }

  .email-input-wrapper {
    font-family: 'Karla', sans-serif !important;
    margin-top: 1rem;

    .email-input-container {
      position: relative;
      border-bottom: 1px solid #ccc;
    }
    input {
      width: 100%;
      padding: 0.375rem 0.75rem !important;
      padding-top: 0 !important;
      padding-left: 0 !important;
      padding-right: 31px;
      height: 35px !important;
      border: none !important;
      padding-bottom: 0 !important;
      font-size: 16px !important;
      border-radius: 0 !important;
      padding-left: 0;
      background-color: #fff;
      background-clip: padding-box;
      line-height: 1.5;
      color: appVar.$input-color;
      z-index: 99999999;
      text-align: left !important;
      box-shadow: none !important;

      &:focus-visible,
      &:active,
      &:hover {
        outline: none !important;
      }
    }
    label {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.5px;
      color: black;
    }
    .validate-icon {
      bottom: 5px;
      position: absolute;
      right: 0;
      width: 31px;
      height: 31px;
    }
  }

  .modal-btn-group {
    text-align: center;
    margin-top: 60px;
    .btn-confirm {
      min-width: 223px;
      height: 56px;

      &:disabled {
        opacity: 0.4;
      }
    }
  }
  .screen-code {
    padding-bottom: 100px;
    .otp-wrapper {
      margin-top: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      input {
        border-radius: 0;
      }
    }
    .otp-resend {
      margin-top: 1rem;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.5px;
      color: black;
      // margin-top: 5px;
      align-self: flex-start;

      .text-link {
        text-decoration: underline;
        color: appVar.$primary-color;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
  .custom-button {
    display: block;
    margin: 0 auto;
    padding: 16px 80px;
  }
}
