@import 'styles/variables';

.not-found {
  min-height: calc(65vh - $navbar-minHeight);

  &-title {
    font-size: 6rem;
    font-weight: bold;
    line-height: 6rem;
  }
}
