@import 'styles/variables';

.offer-how-it-work {
  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  &--header-title {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
  }
  &--header-close {
    cursor: pointer;
  }
  &--content-item {
    margin-bottom: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    column-gap: 8px;
  }
  &--content-item-title {
    font-weight: 500;
    display: block;
    line-height: 24px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $primary-color;
    color: #fff;
    text-align: center;
    flex-shrink: 0;
  }
  &--content-item-description {
    font-size: 16px;
  }
}
