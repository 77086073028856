@import 'styles/mixins';

.featured-card {
  @include for-size(desktop-only) {
    width: fit-content;
  }

  @include for-size(phone-only) {
    width: 100%;
  }

  .featured-card-tag {
    height: 33px;
    width: fit-content;
    border-radius: 17px;
    background-color: #ffd551;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.5px;
    line-height: 19px;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    @include for-size(phone-only) {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 12px;
    }
  }
  .featured-card-container {
    // min-height: 322px;
    // width: 600px;
    min-height: 290px;
    // width: 550px;
    width: 600px;
    border: 1px solid #e8e8e8;
    border-radius: 20px;
    background-color: #ffffff;
    padding-top: 24px;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include for-size(navbar-collapsed) {
      width: 100%;
    }

    @include for-size(phone-only) {
      width: 100%;
      min-height: 300px;
    }

    .featured-card--name {
      font-size: 24px;
      font-weight: bold;
      letter-spacing: -1.44px;
      line-height: 28px;
      padding-left: 24px;

      @include for-size(phone-only) {
        padding-left: 12px;
        font-size: 18px;
      }
    }

    .featured-card--rewards {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      padding-left: 32px;
      padding-right: 32px;
      flex-grow: 1;

      .featured-card--reward {
        .featured-card--reward-img {
          height: 60px;
          width: 60px;
          object-fit: contain;

          @include for-size(phone-only) {
            height: 40px;
            width: 40px;
          }
        }

        .featured-card--reward-value {
          font-size: 24px;
          letter-spacing: -0.75px;
          line-height: 28px;
          text-align: center;
          font-weight: bold;
          @include for-size(phone-only) {
            font-size: 18px;
          }
        }
      }
    }

    .featured-card-bonus {
      height: 90px;
      width: '100%';
      background-color: #8c2137;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      // padding-left: 32px;
      // padding-right: 32px;
      padding-left: 50px;
      padding-right: 50px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @include for-size(phone-only) {
        padding-left: 12px;
        padding-right: 12px;
      }

      .featured-card-bonus-container {
        display: flex;
        flex-direction: row;
        // justify-content: flex-start;
        align-items: center;
        flex-grow: 1;

        &.rewardsBox {
          justify-content: space-between;
        }

        &.specialOffer {
          justify-content: center;
        }

        .featured-card-bonus-specialOffer {
          font-size: 20px;
          letter-spacing: -0.5px;
          line-height: 28px;
          font-weight: bold;
          text-align: center;
          color: #ffffff;
          @include for-size(phone-only) {
            font-size: 18px;
          }

          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .featured-card-bonus--bonus {
          color: #ffffff;
          .featured-card-bonus--title {
            // font-size: 16px;
            font-size: 20px;
            letter-spacing: -0.75px;
            line-height: 28px;
          }

          .featured-card-bonus--value {
            // font-size: 24px;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: -0.5px;
            line-height: 28px;

            @include for-size(phone-only) {
              font-size: 14px;
            }
          }
        }
      }

      .featured-card-bonus--icon {
        width: 30px;
        height: 30px;
      }

      .divider {
        height: 53px;
        width: 1px;
        background-color: #cccccc;
        margin-left: 24px;
        margin-right: 24px;
      }
    }
  }
}

.featured-card-bonus--tellMeMore {
  display: flex;
  margin-top: 30px;
  justify-content: center;
}
.featured-card-bonus--button {
  background-color: transparent;
  border: 1px solid #000000;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.8px;
  line-height: 19px;
  text-align: center;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}
