@import 'styles/variables';
@import 'styles/mixins';

.missed-issue {
  max-width: 1240px;
  margin: 0 auto;
  padding: 30px 20px 20px;
  min-height: calc(100vh - 309px);
  .infobox {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    &-label {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &-header {
      position: relative;
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      &-title {
        font-size: 30px;
        font-weight: 800;
        letter-spacing: -0.94px;
        line-height: 36px;
        margin-bottom: 24px;
      }
    }
  }

  &-thankYou {
    display: flex;
    align-items: center;
    flex-direction: column;
    & h2 {
      font-weight: bold;
    }
    & .icon {
      width: 200px;
      height: 200px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    & button {
      padding: 20px 100px;
    }

    & p {
      margin-bottom: 48px;
      font-size: 18px;
      letter-spacing: -0.8px;
      line-height: 19px;
    }
  }

  &-submitBox {
    display: flex;
    justify-content: center;
    & button {
      padding: 20px 100px;
    }
  }

  &-infoIcon {
    font-size: 16px;
    font-weight: 500;
    position: relative;
    display: inline-flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #000;
    margin-left: 4px;
    cursor: pointer;
    bottom: 12px;
    &:after {
      content: 'i';
      display: flex;
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
      position: relative;
      letter-spacing: normal;
    }
  }

  &-btnBack {
    padding: 0;
    background-color: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: #3f52c7;
    margin-bottom: 20px;
    & img {
      width: 18px;
      height: 18px;
      position: relative;
      bottom: 2px;
    }
  }
}

.reportissue {
  padding-top: 30px;
  padding-bottom: 60px;
  &-container {
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 20px;
  }

  &-btnBack {
    position: absolute;
    left: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: $primary-color;

    & img {
      width: 18px;
      height: 18px;
      position: relative;
      bottom: 2px;
    }
  }

  &-desc {
    font-size: 20px;
    margin-bottom: 24px;
    & .highlight {
      color: $primary-color;
      font-weight: bold;
    }
  }

  &-textarea {
    height: 201px;
    margin-top: 30px;
    margin-bottom: 8px;
  }

  &-label {
    font-size: 18px;
    font-weight: 800;
    letter-spacing: -0.67px;
    line-height: 19px;
    margin-bottom: 16px;
  }

  &-count {
    font-size: 16px;
    color: #666666;
    letter-spacing: -0.5px;
    line-height: 14px;
    margin-bottom: 30px;
    &.maxed {
      color: $err-input;
    }
  }

  &-submitBox {
    display: flex;
    justify-content: center;
    & button {
      padding: 15px 60px;
    }
  }

  .form-check {
    margin-bottom: 16px;
  }

  &-infoIcon {
    font-size: 12px;
    font-weight: 500;
    position: relative;
    display: inline-flex;
    width: 15px;
    height: 15px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #000;
    margin-left: 4px;
    cursor: pointer;
    bottom: 2px;
    &:after {
      content: 'i';
      display: flex;
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
      position: relative;
      letter-spacing: normal;
    }
  }
}
