@import 'styles/variables';

.modal-link-plaid {
  max-width: 600px;
  padding-top: 30px;
  .modal-link-plaid--title {
    font-weight: bold;
    font-size: 30px;
    letter-spacing: -1.25px;
    line-height: 36px;
    text-align: left;
    color: black;
    margin-bottom: 20px;
    text-align: center;
  }

  &--text {
    font-size: 18px;
  }

  &--item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    margin-bottom: 24px;
  }

  &--item-img {
    width: 90px;
    height: 100px;
    background-color: $primary-color;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    img {
      width: 40px;
    }
  }
  &--divider {
    height: 1px;
    width: 100%;
    background-color: $border-gray;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &--item-title {
    font-weight: bold;
    font-size: 18px;
  }

  &--item-subtitle {
    font-size: 18px;
  }

  &--footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    img {
      width: 40px;
    }
  }

  .modal-link-plaid--btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .modal-link-plaid--btn {
    border: 2px solid $primary-color;
    min-width: 200px;
    height: 50px;
    background-color: white;
    border-radius: 30px;
    color: $primary-color;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
  }

  .modal-link-plaid--btn.background {
    background-color: $primary-color;
    color: white;
  }
}
