@import 'styles/variables';
@import 'styles/mixins';

.home-container {
  padding-top: 30px;
  // min-height: calc(100vh - 84px);

  .home-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include for-size(phone-only) {
      width: 100%;
      margin: 0 auto;
      padding-left: 20px;
      padding-right: 20px;
    }

    // @include for-size(desktop-only) {
    //   width: 95%;
    //   margin: 0 auto;
    // }

    // @include for-size(desktop) {
    //   width: 95%;
    //   margin: 0 auto;
    // }

    // @include for-size(desktop-medium) {
    //   width: 75%;
    //   margin: 0 auto;
    // }

    // @include for-size(desktop-lg) {
    //   width: 70%;
    //   margin: 0 auto;
    // }

    max-width: 1440px;
    margin: 0 auto;
    // padding: 0px 20px;
    .home-header-title {
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -1px;
      margin-right: 24px;
      white-space: nowrap;
      span {
        font-weight: bold;
      }
    }
    img {
      width: 38px;
      height: 37px;
      cursor: pointer;
    }
    .home-header-title-container {
      display: flex;
      align-items: center;
    }
    .home-filter-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .home-filter-item {
        height: 41px;
        width: 41px;
        border-radius: 50%;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.96px;
        line-height: 19px;

        display: flex;
        justify-content: center;
        align-items: center;
      }
      .home-filter-item.selected {
        background-color: black;
        color: white;
      }
    }
  }

  .home-header-container.mobile {
    display: none;
  }

  @include for-size(phone-only) {
    .home-header-container.desktop {
      display: none;
    }
    .home-header-container.mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .home-filter-container {
        margin-top: 20px;
      }
    }
  }

  .home-title {
    font-size: 32px;
    font-weight: bold;
    letter-spacing: -1.92px;
    line-height: 37px;
    margin-bottom: 24px;
  }

  .home-body {
    .home-offers-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      margin-bottom: 30px;

      @include for-size(phone-only) {
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
      }

      // @include for-size(desktop-only) {
      //   width: 95%;
      //   flex-direction: column;
      //   margin: 0 auto;
      // }

      // @include for-size(desktop) {
      //   width: 95%;
      //   margin: 0 auto;
      //   flex-direction: row;
      // }

      @include for-size(desktop-medium-sm) {
        // flex-direction: column;
        margin: 0 auto;
        margin-bottom: 60px;
      }

      // @include for-size(desktop-lg) {
      //   width: 70%;
      //   margin: 0 auto;
      //   flex-direction: row;
      // }      // @include for-size(desktop-lg) {
      //   width: 70%;
      //   margin: 0 auto;
      //   flex-direction: row;
      // }

      max-width: 1440px;
      margin: 0 auto 60px auto;
      padding: 0px 20px;
      gap: 160px;
      // gap: 90px;

      @include for-size(desktop-medium-sm) {
        // gap: 70px;
        gap: 30px;
      }

      // max-width: 1240px;
      // margin: 0 auto;
      // flex-direction: row;

      .home-col-1,
      .home-col-2 {
        flex-shrink: 0;
        // padding-top: 40px;
        min-width: 0;
        flex: 1;
      }

      .home-col-2 {
        &.hasLinked {
          padding-top: 35px;
        }
      }

      // @include for-size(desktop-medium-sm) {
      //   .home-col-1 {
      //     display: flex;
      //     justify-content: center;
      //   }
      // }
    }

    .home-recommended-cards {
      background-color: #f6f6f6;
    }
  }

  .home-tag {
    display: block;
    width: fit-content;
    padding: 3px 20px;
    align-items: center;
    background-color: #ffd551;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.37px;
    border-radius: 17px;
    margin-bottom: 20px;
  }

  .home-popularHeader {
    display: flex;
    justify-content: space-between;
  }

  .home-seeAll {
    background-color: transparent;
    border: 1px solid #000000;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.8px;
    line-height: 19px;
    text-align: center;
    height: 34px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.home-popup {
  .popover {
    max-width: 400px;
  }
}

.modal-item {
  & .highlight {
    color: $primary-color;
    font-weight: 500;
  }

  & .custom-btn {
    display: flex;
    justify-content: center;
  }
}

.home-popup-wrapper {
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 16px;
}

.extn-overlay-desktop {
  width: 850px;
}

.extn-btn {
  padding: 16px 50px;
}
