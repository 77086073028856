@import 'styles/variables';

.custom-modal-payout-history-details {
  max-width: 700px;
  .modal-content {
    .modal-view {
      padding: 0 !important;

      .modal-item {
        padding: 0 !important;
      }
    }
  }
}

.modal-payout-history-container {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  .modal-payout-history-header {
    display: flex;
    align-items: center;
    margin-top: 40px;
    padding-bottom: 16px;
    font-weight: bold;
    // font-size: 18px;
    // font-size: 24px;
    font-size: 26px;
  }

  .modal-payout-history-ref-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .modal-payout-history--ref {
      // font-size: 20px;
      font-size: 24px;
      span {
        font-weight: bold;
      }
    }
    img {
      width: 30px;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .modal-payout-history-status {
    font-size: 20px;
    span {
      color: $primary-color;
    }
  }
  .modal-payout-history-redeem-amount {
    // font-size: 18px;
    font-size: 20px;
    border-bottom: 1px solid #9999;
    padding-bottom: 16px;
    margin-top: 16px;

    .modal-payout-history-redeem-amount--amount {
      font-weight: bold;
    }
  }

  .modal-payout-history-commistion-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-top: 16px;
    padding-bottom: 16px;

    border-bottom: 1px solid #9999;

    &:last-child {
      border-bottom: none;
    }

    .modal-payout-history-commistion-item--name {
      // font-size: 18px;
      font-size: 20px;
      font-weight: bold;
    }
    .modal-payout-history-commistion-item--spent {
      // font-size: 18px;
      font-size: 20px;
    }
    .modal-payout-history-commistion-item--date {
      // font-size: 16px;
      font-size: 20px;
    }

    .modal-payout-history-commistion-item--amount {
      // font-size: 20px;
      font-size: 24px;
      font-weight: bold;
    }
  }
}
