@import 'styles/variables';

.user-survey {
  padding-top: 30px;
  padding-bottom: 60px;

  &-container {
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 20px;
  }
  &--tag {
    background-color: #ffd551;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  &-header {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    & h1 {
      font-size: 30px;
      margin: 0px;
    }
  }
  &-btnBack {
    position: absolute;
    left: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: $primary-color;

    & img {
      width: 18px;
      height: 18px;
      position: relative;
      bottom: 2px;
    }
  }
  .user-survey-container {
    .user-survey-body {
      min-height: 600px;
      .user-survey-title {
        font-size: 20px;
        font-weight: bold;
      }
      label {
        font-size: 18px;
        margin-left: 18px;
        margin-bottom: 0;
      }

      .user-survey-main-checkbox {
        width: 25px;
        height: 25px;
        margin-top: 0;
      }

      .form-check-input:checked {
        background-color: $primary-color;
        border-color: $primary-color;
      }

      .user-survey-main-radio {
        width: 25px;
        height: 25px;
        margin-top: 0;
      }

      .user-survey-category-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }

      .user-survey-fake-checkbox {
        width: 25px;
        height: 25px;
        border: 1px solid #dee2e6;
        border-radius: 0.25rem;
        cursor: pointer;
      }

      .user-survey-fake-checkbox.active {
        background-color: $primary-color;
      }

      .user-survey-category-multi-container {
        margin-bottom: 10px;
        .user-survey-category-multi--list {
          display: flex;
          align-items: center;
          gap: 20px;
          margin-left: 30px;
          margin-top: 10px;
          margin-bottom: 10px;

          .user-survey-category--item {
            border: 1px solid #ddd;
            width: 50px;
            height: 50px;
            border-radius: 12px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              border-radius: 12px;
            }
            input {
              position: absolute;
              width: 20px;
              height: 20px;
              top: -10px;
              right: -10px;
            }
          }
        }
      }
    }
    .custom-button {
      min-width: 190px;
      margin: 0 auto;
      display: block;
      margin-top: 30px;
    }
  }
}
