@import 'styles/variables';

.custom-modal-redeem-savings {
  max-width: 500px;
  .modal-content {
    .modal-view {
      padding: 0 !important;

      .modal-item {
        padding: 0 !important;
      }
    }
  }
}

.modal-redeem-savings-container {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  .modal-redeem-savings-title {
    display: flex;
    align-items: center;
    margin-top: 40px;
    padding-bottom: 16px;
    font-weight: bold;
    font-size: 20px;
  }

  .clock-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-top: 16px;
    margin-bottom: 30px;
  }

  .modal-redeem-savings-desc {
    font-size: 14px;
    letter-spacing: -0.35px;
    line-height: normal;
  }
  .custom-button {
    display: block;
    min-width: 200px;
    margin: 0 auto;
    margin-top: 60px;
  }

  .btn-redeem-history {
    background-color: white !important;
    color: $primary-color !important;
  }

  .btn-my-savings {
    margin-top: 24px;
    background-color: transparent !important;
    color: $primary-color !important;
    border-color: transparent;
  }
}
