@use 'styles/variables' as appVar;
@import 'styles/mixins';

.custom-modal-offer-details {
  max-width: 500px;
  .modal-content {
    .modal-view {
      padding: 0 !important;

      .modal-item {
        padding: 0 !important;
      }
    }
  }
}

.offer-details-container {
  padding-top: 50px;
  .offer-details-body {
    padding: 24px;
    max-height: 600px;
    // overflow-x: scroll;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      height: 5px;
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #c5c5c5;
      border-radius: 100px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #ddd;
    }
    @include for-size(phone-only) {
      max-height: 440px;
    }
  }
  .offer-details-best-card-for {
    height: 24px;
    width: fit-content;
    padding: 8px 12px 8px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffd551;

    font-size: 16px;
    letter-spacing: -0.37px;

    border-radius: 12px;

    margin-bottom: 20px;
  }

  .offers-details-card-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin-bottom: 20px;

    .offer-card-img {
      width: 130px;
      height: 80px;
      border-radius: 8px;

      margin-right: 16px;
    }
    .offer-card-text {
      font-size: 18px;
      letter-spacing: -0.5px;
    }
  }
  .offer-reward {
    font-size: 16px;
    letter-spacing: -0.5px;
    margin-bottom: 16px;
  }
  .offer-reward-text {
    font-weight: bold;
  }
  .offer-details-save-more {
    height: 24px;
    width: fit-content;
    padding: 8px 12px 8px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(2, 2, 2, 0.1);

    font-size: 14px;
    letter-spacing: -0.37px;
    font-weight: bold;

    border-radius: 12px;

    margin-bottom: 10px;
  }

  .offer-details-rate-container {
    width: 100%;
    height: 'auto';
    background-color: #f4f6ff;
    border-radius: 8px;
    padding: 16px;

    .how-it-work-text {
      font-size: 14px;
      letter-spacing: -0.5px;
      text-align: left;
      margin-bottom: 10px;
      line-height: 18px;
    }

    .offer-details-rate-title {
      font-size: 16px;
      letter-spacing: -0.5px;
      text-align: left;
      margin-bottom: 10px;

      span {
        font-weight: bold;
      }
    }
    .offer-details-rate-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      font-size: 14px;

      .get-offer {
        text-decoration: underline;
        cursor: pointer;
      }
      .tc-apply {
        text-decoration: underline;
      }
      .btn-close-details {
        cursor: pointer;
      }
    }
  }
  .offer-details-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid appVar.$border-gray;

    height: 90px;
    width: 100%;

    .offer-details-footer-item {
      flex-basis: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      &:first-child {
        border-right: 1px solid appVar.$border-gray;
      }

      .offer-details-footer-icon {
        width: 24px;
        height: 24px;
        margin-bottom: 8px;
      }
      .offer-details-footer--text {
        font-size: 16px;
        letter-spacing: -0.67px;
        color: appVar.$primary-color;
      }
    }
  }
  .offer-details-premium {
    display: flex;
    height: 24px;
    width: fit-content;
    align-items: center;
    border-radius: 12px;
    padding: 4px;
    padding-right: 8px;
    margin-top: 20px;
    margin-bottom: 10px;

    background-color: appVar.$primary-color;
    .offer-details-premium-icon-container {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: 10px;

      .icon-check {
        width: 13px;
        height: 13px;
      }
    }
    .offer-details-premium-text {
      font-size: 14px;
      letter-spacing: -0.37px;
      color: white;
    }
  }

  .offer-details-footer-container {
    padding-top: 10px;
    .custom-button {
      text-align: center;
      margin: 0 auto;
      display: block;
      width: 150px;

      margin-bottom: 16px;
    }
  }
}

.Offer-coupon-container {
  width: 100%;
  height: 'auto';
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 16px;

  .Offer-coupon-description {
    font-size: 14px;
    letter-spacing: -0.37px;
  }
  .Offer-coupon-code {
    font-size: 14px;
    letter-spacing: -0.37px;
    span {
      font-weight: bold;
    }
  }
}

.best-card-bank-offer {
  width: 100%;
  height: 'auto';
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 16px;
}

.offer-bank-offer-title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.37px;
}

.premium-offer-activated-container {
  width: 100%;
  height: 'auto';
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 16px;

  margin-top: 10px;
  margin-bottom: 10px;

  .premium-offer-activated-card {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    img {
      margin-right: 20px;
      width: 100px;
      height: 60px;
    }
    .premium-offer-activated-card-info {
      font-size: 18px;
      letter-spacing: -0.5px;
    }
  }

  .premium-offer-activated-lang {
    text-align: left;
    font-size: 14px;
    letter-spacing: -0.37px;
  }
  .premium-offer-activated-tc {
    cursor: pointer;
    font-size: 12px;
    text-align: right;
    text-decoration: underline;
  }
}

// .custom-modal-offer-tc {
//   .offerDescription-container {
//     padding-top: 40px;
//   }
// }
