@import 'styles/variables';

.earned-reward-notvalidate {
  &-title {
    margin-bottom: 0px;
    // font-size: 24px;
    font-size: 18px;
    font-weight: 400;
  }
  &-item {
    position: relative;
    box-sizing: border-box;
    border: 1px solid #e8e8e8;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 16px 42px 16px 16px;

    display: flex;
    flex-direction: column;
    gap: 62px;
  }

  &-link {
    text-decoration: underline;
    color: $primary-color;
    font-size: 16px;
    cursor: pointer;
    width: fit-content;
  }

  &-image {
    & img {
      height: 50px;
      width: auto;
      border-radius: 10px;
    }
  }

  &-infoBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
