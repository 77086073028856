@import 'styles/variables';

.popover-download-app {
  .popover-header {
    background-color: white;
    border-bottom: none;
    padding-top: 12px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .popover-download-app--title {
      color: $primary-color;
      // font-size: 18px;
      // font-size: 20px;
      font-size: 23px;
      font-weight: bold;
    }
  }

  &--icon-close {
    cursor: pointer;
    margin-left: 10px;
  }

  &--qr-container {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--subtitle {
    font-size: 16px;
    margin-top: 14px;
    text-align: center;
    line-height: 18px;
  }

  &--btn {
    width: 100%;
    border-radius: 8px;
    margin-top: 20px;
  }

  &.sm {
    .popover {
      max-width: 300px;
    }
  }

  &.md {
    .popover {
      max-width: 400px;
    }
  }

  &.bg {
    .popover {
      max-width: 1000px;
    }
  }
}
