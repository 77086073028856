@import 'styles/variables';
@import 'styles/mixins';

.spend-calculator-container {
  margin-top: 3.5rem;
  // height: 300px;
  width: 625px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 24px 40px 40px 32px;

  @include for-size(phone-only) {
    width: 100%;
  }

  @include for-size(desktop-only) {
    max-width: 100%;
  }

  .spend-calculator-slider {
    margin-top: 30px;
    &--thumb {
      width: 32px;
      height: 32px;
      top: -16px;
      border-radius: 50%;
      background-color: white;
      border: 6px solid $primary-color;

      &:focus-visible {
        outline: none;
      }
    }
    &--track {
      height: 3.65px;
    }
    &--track-0 {
      background-color: $primary-color;
    }
    &--track-1 {
      background-color: #d8d8d8;
    }
  }
  .spend-calculator-slider-values {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;

    .spend-calculator-slider--value {
      font-size: 20px;
      letter-spacing: -1.2px;
      line-height: 23px;
    }
  }
  .spend-calculator-reward-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    .spend-calculator-reward {
      // margin-top: 30px;
      margin-top: 15px;
      .spend-calculator-reward-spend--title {
        font-size: 20px;
        letter-spacing: -1.2px;
        line-height: 23px;
        text-align: center;

        @include for-size(phone-only) {
          font-size: 18px;
        }
      }
      .spend-calculator-reward-spend--value {
        font-size: 32px;
        font-weight: bold;
        letter-spacing: -1.92px;
        line-height: 37px;
        text-align: center;

        @include for-size(phone-only) {
          font-size: 20px;
        }
      }
      .spend-calculator-reward-missed--title {
        color: #ff655e;
        font-size: 20px;
        letter-spacing: -1.2px;
        line-height: 23px;
        text-align: center;

        .spend-calculator-reward-missed--note {
          display: inline-block;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          border: 1px solid #ff655e;
          margin-left: 4px;
          cursor: pointer;
        }
      }
      .spend-calculator-reward-missed--value {
        color: #ff655e;
        font-size: 32px;
        font-weight: bold;
        letter-spacing: -1.92px;
        line-height: 37px;
        text-align: center;

        @include for-size(phone-only) {
          font-size: 20px;
        }
      }
    }
  }
  .spend-calculator--btn {
    height: 40px;
    max-width: 300px;
    border: 1px solid #000000;
    border-radius: 28px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.8px;
    line-height: 19px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    // margin-top: 34px;
    margin-top: 20px;
    cursor: pointer;

    @include for-size(phone-only) {
      font-size: 14px;
    }
  }
}

.modal-annual-reward-note {
  max-width: 700px;
  .modal-content {
    .modal-view {
      padding: 0 !important;

      .modal-item {
        padding: 0 !important;
      }
    }
  }

  .modal-annual-reward-note-container {
    padding: 20px 80px 50px;
  }

  .modal-annual-reward-note--title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
    padding-top: 30px;
  }
}
