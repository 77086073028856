.custom-modal-saving-offer-details {
  max-width: 700px;
  .modal-content {
    .modal-view {
      padding: 0 !important;

      .modal-item {
        padding: 0 !important;
      }
    }
  }
}

.saving-offer-detail-container {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  .saving-offer-detail-header {
    display: flex;
    align-items: center;
    margin-top: 40px;
    padding-bottom: 16px;

    border-bottom: 1px solid #9999;

    img {
      width: 80px;
      object-fit: contain;
      border: 1px solid #9999;
      border-radius: 8px;
      margin-right: 15px;
    }

    .saving-offer-detail-header--info-container {
      .saving-offer-detail-header--info-name {
        flex: 1;
        font-weight: bold;
        // font-size: 20px;
        font-size: 24px;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .saving-offer-detail-header--info-amount {
        // font-size: 18px;
        font-size: 20px;
      }
      .saving-offer-detail-header--info-date {
        // font-size: 18px;
        font-size: 20px;
      }
    }
  }

  .saving-offer-detail--cashback {
    width: 100%;
    height: 60px;
    background-color: #9999992d;
    display: flex;
    align-items: center;
    padding: 10px;
    // font-size: 20px;
    font-size: 24px;
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .saving-offer-detail--offer-savings {
    width: 100%;
    // height: 80px;
    height: auto;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #9999;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .saving-offer-detail--offer-savings-title {
      // font-size: 20px;
      font-size: 24px;
    }
    .saving-offer-detail--offer-savings-amount {
      // font-size: 20px;
      font-size: 24px;
      font-weight: bold;
    }
  }

  .saving-offer-detail--offer-status {
    .saving-offer-detail--offer-status-title {
      // font-size: 20px;
      font-size: 24px;
    }
    .saving-offer-detail--offer-status-value {
      // font-size: 20px;
      font-size: 24px;
      font-weight: bold;
    }
    .saving-offer-detail--offer-status-note {
      // font-size: 16px;
      font-size: 18px;
      margin-top: 20px;
    }
  }
}
