@import 'styles/variables';
@import 'styles/mixins';

.rewards-missed {
  &-container {
    padding-top: 20px;

    .rewards-missed-filter--month {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 120px;

      margin: 0 auto;
      margin-bottom: 30px;

      img {
        width: 14px;
        cursor: pointer;
      }

      .rewards-missed-filter--month-value {
        font-size: 16px;
        letter-spacing: -0.5px;
        line-height: 19px;
      }
    }
  }
  &-flexbox {
    display: flex;
    // column-gap: 31px;
    // column-gap: 80px;
    column-gap: 100px;
    & .infobox {
      width: 55%;

      & h2 {
        font-size: 24px;
      }

      & .transaction-item {
        margin-bottom: 20px;
        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
      }

      &-label {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
      }

      &-filter {
        display: flex;
        gap: 16px;
        align-items: flex-start;
        margin-bottom: 32px;
        & button {
          background-color: transparent;
          border: none;
          padding: 0px;
          font-weight: bold;
          color: #666666;
          &.active {
            color: #000;
            border-bottom: 4px solid #000;
          }
        }
      }

      &-reward {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 24px;
        margin-bottom: 24px;
        & .title {
          display: flex;
          gap: 12px;
          align-items: flex-end;
          & .rewards-image-container {
            & img {
              width: 100px;
              height: auto;
            }
          }
          & h2 {
            margin: 0px;
            font-size: 18px;
          }
        }
        &-sm {
          display: flex;
          &-item {
            display: flex;
            flex-direction: column;
            width: 110px;
            &:first-child {
              padding-right: 12px;
            }
            &:last-child {
              padding-left: 12px;
              align-self: stretch;
              border-left: 1px solid lightgrey;
            }
          }

          &-title {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 10px;
          }

          &-price {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 0px;
            &.missed {
              font-weight: bold;
            }
          }
        }
      }

      & .custom-button {
        display: block;
        margin: 0 auto;
        margin-top: 60px;
        color: $primary-color;
        background-color: #fff;
      }
    }
    & .cardbox {
      width: 45%;
      .earned-reward-item {
        margin-bottom: 30px;
      }
      & .missed-reward-item {
        margin-bottom: 30px;
      }

      &-title {
        & h2 {
          font-size: 24px;
          font-weight: bold;
        }
        margin-bottom: 16px;
      }
    }
  }

  &-transactitle {
    font-weight: bold;
    margin-bottom: 24px;
  }
}
