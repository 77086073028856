.banner-footer-container {
  width: 100%;
  height: 45px;
  background-color: black;
  padding-right: 20px;

  justify-content: space-between;
  align-items: center;

  position: relative;

  .banner-footer-icon-close {
    width: 30px;
    cursor: pointer;
  }

  .banner-footer-content {
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    .banner-footer-text {
      color: #ffffff;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -1.44px;
      line-height: 28px;
      text-align: center;
    }
    img {
      width: 30px;
      height: 24px;
      margin-left: 10px;
      object-fit: contain;
    }
  }

  .banner-footer-arrow-up {
    position: absolute;
    top: -10px;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid black;
  }

  &.active {
    display: flex;
  }

  &.inactive {
    display: none;
  }
}
