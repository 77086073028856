@use 'styles/variables' as appVar;
@import 'styles/mixins';

.earned-chart-container {
  margin-top: 20px;
  @include for-size(phone-only) {
    width: 100%;
  }
  @include for-size(desktop-only) {
    max-width: 100%;
  }
  .earned-chart-rewards-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;

    @include for-size(phone-only) {
      gap: 10px;
    }

    .earned-chart-reward {
      .earned-chart-reward--title {
        font-size: 32px;
        font-weight: bold;
        letter-spacing: -1.92px;
        line-height: 37px;
        margin-bottom: 10px;
      }

      .earned-chart-reward--value {
        font-size: 35px;
        letter-spacing: 0;
        line-height: 56px;
        font-weight: bold;
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          font-family: 'Sharp Grotesk Medium 25 Regular';
          margin-right: 10px;
        }

        img {
          height: 27px;
        }
      }

      @include for-size(phone-only) {
        .earned-chart-reward--title {
          font-size: 18px;
          margin-bottom: 0;
        }

        .earned-chart-reward--value {
          font-size: 25px;
          line-height: 40px;
        }
      }
    }
  }
  .earned-charts {
    margin-top: 20px;

    .earned-chart-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // height: 101px;
      height: 82.5px;
      @include for-size(desktop-medium-sm) {
        height: 79.5px;
      }
      width: auto;
      border-radius: 12px;
      background-color: #ffffff;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
      padding: 28px 30px 28px 30px;
      cursor: pointer;
      gap: 30px;
      &:first-child {
        margin-bottom: 16px;
      }

      @include for-size(phone-only) {
        width: 100%;
        padding: 18px 15px 18px 15px;
      }

      @include for-size(desktop-only) {
        max-width: 625px;
      }

      .earned-chart-value-container {
        display: flex;
        align-items: center;
        flex-grow: 1;
      }

      .earned-chart-title {
        font-size: 24px;
        letter-spacing: -1.44px;
        line-height: 28px;
        margin-right: 40px;

        @include for-size(phone-only) {
          margin-right: 12px;
          font-size: 18px;
        }
      }

      .earned-chart-row-container {
        width: 100%;
        .earned-chart-row-track {
          position: relative;
          color: #ffffff;
          font-family: 'Sharp Grotesk Medium 25 Regular';
          font-size: 24px;
          letter-spacing: 0;
          line-height: 31px;
          padding-left: 20px;
          white-space: nowrap;

          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 100%;
          background-color: appVar.$primary-color;
          border-radius: 0 21.5px 21.5px 0;

          @include for-size(phone-only) {
            font-size: 20px;
          }
        }
        .earned-chart-row-track.missed {
          background-color: #ff655e;
        }
        .earned-chart-row-background {
          height: 45px;
          // width: 387px;
          border-radius: 0 21.5px 21.5px 0;
          background-color: #f1f1f1;

          @include for-size(phone-only) {
            // max-width: 100%;
            width: 100%;
          }

          @include for-size(desktop-only) {
            max-width: 100%;
          }
        }
      }
      img {
        height: 27px;
        justify-self: flex-end;
      }
    }
  }
}
