@import 'styles/variables';

.custom-modal-redeem-savings {
  max-width: 500px;
  .modal-content {
    .modal-view {
      padding: 0 !important;

      .modal-item {
        padding: 0 !important;
      }
    }
  }
}

.modal-redeem-savings-container {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  .modal-redeem-savings-title {
    display: flex;
    align-items: center;
    margin-top: 40px;
    padding-bottom: 16px;
    font-weight: bold;
    font-size: 20px;
  }

  .modal-redeem-savings-steps {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    .modal-redeem-savings-steps--step {
      color: #999;
      background-color: white;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      border: 1px solid #999;
      font-size: 16px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .modal-redeem-savings-steps--line {
      height: 1px;
      width: 30px;
      margin-left: 4px;
      margin-right: 4px;
      background-color: #999;
    }

    .modal-redeem-savings-steps--step.completed {
      background-color: $primary-color;
      color: white;
      border-color: $primary-color;
    }
  }

  .modal-redeem-savings-subtitle {
    padding-bottom: 16px;
    font-weight: bold;
    font-size: 18px;

    span {
      color: $primary-color;
    }
  }

  .modal-redeem-savings-note-container {
    border: 1px solid #999;
    border-radius: 8px;
    padding: 10px;
    .modal-redeem-savings-note-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    .modal-redeem-savings-note-subtitle {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 16px;
      text-align: center;
    }
    .modal-redeem-savings-note-container-btn-group {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      .custom-button {
        margin: 0 !important;
        margin-top: 60px !important;
        &:first-child {
          background-color: white !important;
          color: $primary-color !important;
          min-width: 100px !important;
        }
      }
    }
  }

  .modal-redeem-savings-desc {
    font-size: 14px;
    letter-spacing: -0.35px;
    line-height: normal;
  }
  .custom-button {
    display: block;
    min-width: 200px;
    margin: 0 auto;
    margin-top: 60px;
  }

  .modal-redeem-savings-amount-container {
    margin-bottom: 20px;
    .modal-redeem-savings-amount-title {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      div {
        font-weight: bold;
      }
      img {
        width: 30px;
        margin-right: 8px;
      }
    }

    .modal-redeem-savings-amount-redeem-value {
      font-size: 16px;
      margin-top: 16px;
    }
  }

  .modal-redeem-savings-email-container {
    .modal-redeem-savings-email--label-container {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      img {
        width: 30px;
        margin-right: 8px;
      }
    }
    .modal-redeem-savings-email--label {
      font-size: 16px;
      font-weight: bold;
    }
    .error-text {
      font-size: 12px;
      color: $err-input;
      margin-top: 8px;
    }
  }
  .modal-redeem-savings-do-not-account {
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
    span {
      color: $primary-color;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .modal-redeem-savings-step2-container {
    min-height: 300px;
    display: flex;
    flex-direction: column;

    .modal-redeem-savings-step2-title {
      font-size: 16px;
      font-weight: bold;
      span {
        color: $primary-color;
      }
    }

    .modal-redeem-savings-progress-payout {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      flex-grow: 1;

      .progress {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 1px solid #999;
        background-color: white;

        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        width: 30px;
      }
    }
    .modal-redeem-savings-step2-loader {
      height: 80px;
      margin-top: 16px;
      margin-bottom: 16px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
