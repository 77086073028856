@use 'styles/variables' as appVar;

.step1-grp {
  margin-top: 32px;
  margin-bottom: 0px !important;
  .title {
    font-weight: bold;
    font-size: 30px;
    letter-spacing: -1.25px;
    line-height: 36px;
    text-align: left;
    color: black;
  }
  .subTitle {
    font-size: 16px;
    letter-spacing: -0.67px;
    color: black;
    .text-link {
      text-decoration: underline;
      color: appVar.$primary-color;
      font-weight: bold;
      cursor: pointer;
    }
  }
  .notice-text {
    color: black;
    font-size: 16px;
    letter-spacing: -0.67px;
    margin-top: 40px;
  }
}
