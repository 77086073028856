@import 'styles/variables';
@import 'styles/mixins';

.activated-offers-container {
  max-width: 760px;
  min-height: calc(100vh - 86px);

  margin: 0 auto;
  margin-top: 30px;

  @include for-size(phone-only) {
    margin-top: 20px;
  }

  .activated-offers--top-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    .activated-offers--top-btn {
      width: fit-content;
      padding: 10px 16px;
      border-radius: 10px;
      border: 2px solid $primary-color;
      color: $primary-color;
      background-color: #ffffff;
      font-size: 16px;
      &:active {
        opacity: 0.5;
      }
    }
  }

  .activated-offers-search-container {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    img {
      width: 20px;
      height: 20px;
    }
    input {
      border: none;
      border-radius: 0;

      &:active,
      &:focus-visible {
        outline: none;
        box-shadow: none;
      }
    }

    .clearn-search-icon {
      cursor: pointer;
    }
  }

  .activated-offers-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include for-size(phone-only) {
      padding-left: 20px;
      padding-right: 20px;
    }
    .activated-offers-header--col1,
    .activated-offers-header--col2 {
      .activated-offers-select-container {
        width: 198px;

        .activated-offers-select__control {
          border-color: black;

          .activated-offers-select__placeholder {
            color: black;
          }
        }
      }
    }

    .activated-offers-header--col2 {
      display: inline-flex;
      column-gap: 5px;
    }
  }

  .activated-offers-header--title {
    font-size: 16px;
    letter-spacing: -0.75px;
  }

  .activated-offers-header--amount {
    font-size: 40px;
    font-weight: bold;
    letter-spacing: -1.25px;
    line-height: 47px;
  }

  .activated-offers-body {
    .activated-offers-tabs {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-top: 16px;
      margin-bottom: 30px;
      .activated-offers-tab {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 50%;

        border-bottom: 5px solid #999999;
        padding-bottom: 16px;
        cursor: pointer;

        .activated-offers-tab--img {
          height: 19px;
          width: 20px;
          margin-right: 8px;
        }

        .activated-offers-tab--title {
          color: #999999;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: -0.5px;
          line-height: 19px;
        }
      }
      .activated-offers-tab.active {
        border-bottom: 5px solid $primary-color;

        .activated-offers-tab--title {
          color: $primary-color;
        }
      }
    }

    .activated-offers-categories-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .activated-offers-categories {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      overflow-x: scroll;
      gap: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 20px;

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: transparent;
      }
      &::-webkit-scrollbar {
        display: none;
        height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #f5f5f5;
      }

      .activated-offers-category {
        // width: fit-content;
        // height: 48px;
        // min-width: 90px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // flex-shrink: 0;
        // padding: 8px 20px 8px 20px;
        // border: 1px solid #999999;
        // background-color: white;
        // font-size: 16px;
        // font-weight: bold;
        // letter-spacing: -0.5px;

        // cursor: pointer;

        // border-radius: 30px;
        min-width: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        padding: 5px 20px;
        border: 1px solid #999999;
        background-color: white;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.5px;
        cursor: pointer;
        border-radius: 30px;
      }
      .activated-offers-category.selected {
        border-color: $primary-color;
        background-color: $primary-color;
        color: white;
      }
    }
    .activated-offers-categories--action {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      height: 100%;
      padding-bottom: 16px;
      margin-left: 16px;

      @include for-size(phone-only) {
        display: none;
      }

      .activated-offers-categories--pre-icon,
      .activated-offers-categories--next-icon {
        width: 42px;
        height: 42px;
        cursor: pointer;
      }
    }

    .activated-offers-load-more {
      padding-top: 40px;
      padding-bottom: 40px;
      width: 100%;
      .activated-offers-load-more-btn {
        height: 50px;
        width: 150px;
        border: 1px solid #979797;
        border-radius: 53px;
        background-color: #ffffff;
        cursor: pointer;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: -1.2px;
        line-height: 28px;
        text-align: center;
        margin: 0 auto;

        display: flex;
        align-items: center;
        justify-content: center;

        &:active {
          opacity: 0.5;
          animation: linear ease-in-out;
          animation-duration: 3;
        }

        @include for-size(phone-only) {
          height: 50px;
          width: 200px;
          font-size: 18px;
        }
      }
      @include for-size(phone-only) {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }
  .activated-offers-list {
    display: flex;
    flex-wrap: wrap;
    padding-right: 10px;
    margin-top: 20px;
    padding-top: 10px;

    @include for-size(phone-only) {
      padding-left: 20px;
    }
  }
  .activated-offers-show-popover-btn {
    height: 50px;
    width: 300px;
    border: 1px solid $primary-color;
    border-radius: 53px;
    background-color: #ffffff;
    color: $primary-color;
    font-size: 18px;

    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
  }
}

.search-btn {
  width: 38px;
  height: 38px;
  border-radius: 4px;
  border-width: 1px;
  background-color: #ffffff;
  .search-icon {
    width: 20px;
    height: 20px;
  }

  &.active {
    background-color: $primary-color;
    border-color: $primary-color;
    .search-icon {
      filter: invert(1);
    }
  }
}
