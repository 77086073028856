.threedots-container {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;

  z-index: 9999999999;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;

  .loading-text {
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
  }
}

.threedots-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.2);
  background-color: #fff;
  z-index: 99999999;
  top: 0;
  left: 0;
  overflow: hidden;
  // backdrop-filter: blur(18px);
}
