@import 'styles/mixins';

.offer-item {
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 190px;

  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #f7f7f7;
  padding-bottom: 10px;

  cursor: pointer;

  @include for-size(phone-only) {
    width: calc(100% / 2 - 8px);
    height: 235px;
  }

  .offer-item-background {
    width: 100%;
    // height: 185px;
    height: 100px;
    object-fit: cover;

    @include for-size(phone-only) {
      height: 125px;
    }
  }

  .offer-item-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 24px;
    padding-right: 5px;
    padding-top: 30px;

    position: relative;
    flex: 1;
    border-top: solid 1px #dfe5eb;
    .offer-item-name {
      text-align: left;
      min-width: 60px;
    }

    @include for-size(phone-only) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .offer-item-logo {
    height: 57px;
    width: 57px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    object-fit: cover;

    position: absolute;
    top: -28px;
  }
  .lock-icon {
    height: 47px;
    width: 47px;
    position: absolute;
    top: -23.5px;
    right: 24px;
    border-radius: 50%;

    @include for-size(phone-only) {
      width: 35px;
      height: 35px;
      top: -17.5px;
      right: 12px;
    }
  }
  .icon-next {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    top: 10px;
    position: relative;

    @include for-size(phone-only) {
      width: 20px;
      height: 20px;
    }
  }
  .name {
    flex: 1;
    font-size: 18px;
    letter-spacing: -0.75px;
    line-height: 28px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @include for-size(phone-only) {
      font-size: 18px;
    }
  }
  .reward {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.75px;
    line-height: 28px;
    text-align: left;

    @include for-size(phone-only) {
      font-size: 18px;
    }
  }
}

.modal-dialog {
  &.modal-offer-item {
    width: 25%;
    & .modal-view {
      padding: 0 !important;
    }

    & .modal-item {
      padding: 50px 20px;
    }
    .custom-button {
      margin-bottom: 0px;
    }

    .content {
      // min-height: 620px;
      height: calc(100vh - 230px);
      overflow-y: scroll;
      &::-webkit-scrollbar {
        height: 5px;
        width: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #c5c5c5;
        border-radius: 100px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #ddd;
      }
    }
  }

  .offer-item {
    height: auto;
    width: 100%;
    cursor: default;

    .offer-item-background {
      width: 100%;
      // height: 185px;
      height: calc(300px / 2);
      object-fit: cover;

      @include for-size(phone-only) {
        height: 125px;
      }
    }

    .name {
      font-size: 22px;

      @include for-size(phone-only) {
        font-size: 18px;
      }
    }
    .reward {
      font-size: 24px;

      @include for-size(phone-only) {
        font-size: 18px;
      }
    }

    &-howItWorks {
      margin: 16px 0px;
      padding-left: 24px;
      padding-right: 24px;
      &-rate-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        font-size: 14px;

        .btn-close-details {
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .get-offer {
        text-decoration: underline;
        cursor: pointer;
      }
      .tc-apply {
        text-decoration: underline;
      }
      .btn-close-details {
        cursor: pointer;
      }

      .how-it-work-text {
        font-size: 14px;
        letter-spacing: -0.5px;
        text-align: left;
        margin-top: 16px;
        line-height: 18px;
      }
    }
  }

  .title {
    font-weight: bold;
    letter-spacing: -2.4px;
    line-height: 30px;
    .highlight {
      font-weight: bold;
    }
    margin-bottom: 30px;
  }

  .desc {
    & .height {
      font-weight: bold;
    }
    margin-top: 30px;
    ol {
      counter-reset: step-counter;
      & li {
        counter-increment: step-counter;
        position: relative;
        margin-bottom: 16px;
        &::marker {
          content: '';
        }
        &::before {
          content: counter(step-counter);
          width: 20px;
          height: 20px;
          text-align: center;
          line-height: 16px !important;
          font-size: 12px;
          background-color: #3f52c7;
          border: 0.125rem solid #3f52c7;
          color: #fff;
          border-radius: 1rem;
          position: absolute;
          top: 0;
          left: -31px;
        }
      }
    }
  }
}
