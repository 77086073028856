@import 'styles/mixins';

.see-all-offer-item {
  // cursor: pointer;
  display: flex;
  justify-content: center;
  @include for-size(phone-only) {
    width: calc(100% / 2 - 8px);
    height: 235px;
  }

  .see-all-offer-item-name {
    display: flex;
    background: transparent;
    align-items: center;
    justify-content: center;
    padding: 8px 10px 8px 10px;
    min-width: 140px;
    border: 1px solid #000000;
    border-radius: 20px;
  }

  .name {
    flex: 1;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: -0.6px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
  }
}
