@keyframes bouncingLoader {
  to {
    opacity: 0.9;
    transform: translateY(-16px);
  }
}

.dots-loader-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dots-loader-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 50px;
  div {
    width: 16px;
    height: 16px;
    margin: 3px 6px;
    border-radius: 50%;
    background-color: #3f52c7;
    opacity: 1;
    animation: bouncingLoader 0.6s infinite alternate;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}
