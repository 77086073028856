@use 'styles/variables' as appVar;
@import 'styles/mixins';

.latest-offer-item-container {
  // width: 130px;
  // min-height: 200px;
  min-height: 185px;
  border-radius: 10px;
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;

  @include for-size(desktop-medium-sm) {
    // min-width: 100px;
    // min-height: 165px;
    min-height: 175px;
  }

  .latest-offer-item-info-container {
    width: 100%;
    text-align: center;

    .name {
      height: 24px;
      flex: 1;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      @include for-size(desktop-medium-sm) {
        font-size: 12px;
      }
    }
    .reward {
      height: 30px;
      font-size: 20px;
      text-align: center;

      @include for-size(desktop-medium-sm) {
        font-size: 16px;
      }

      @include for-size(phone-only) {
        font-size: 18px;
      }
    }
  }

  .latest-offer-item-logo {
    max-width: 90%;
    height: 65px;
    object-fit: contain;
    border-radius: 4px;
  }
  .latest-offer-item--see-details {
    color: appVar.$primary-color;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.67px;
    line-height: 19px;
    text-decoration: underline;
    cursor: pointer;

    @include for-size(phone-only) {
      font-size: 14px;
    }
  }
}
