@import 'styles/variables';
@import 'styles/mixins';

.transaction {
  &-card {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 16px;
    & img {
      width: 45px;
    }

    & .name {
      font-size: 16px;
    }
  }

  &-infobox {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 16px;
    cursor: pointer;
    & .info {
      display: flex;
      flex-direction: column;
      &-name {
        font-size: 20px;
      }
      &-date {
        font-size: 16px;
      }
      &-spent {
        font-size: 16px;
      }
    }

    & .reward {
      font-size: 30px;
      font-weight: bold;
      & img {
        margin-left: 16px;
        width: 20px;
      }
    }
  }
  &-report {
    display: block;
    margin-bottom: 16px;
    cursor: pointer;
    color: $primary-color;
  }
}
