@import 'styles/variables';
@import 'styles/mixins';

.card-validate-selection {
  max-width: 700px;
  margin: 0 auto;
  padding-top: 10px;
  position: relative;
  min-height: 800px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 120px;

  &-title {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.94px;
    line-height: 36px;
    margin-bottom: 16px;
  }

  &-subtitle {
    font-size: 18px;
    letter-spacing: -0.94px;
    line-height: 25px;
    margin-bottom: 16px;
  }

  &-search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;

    input {
      border: none;
      width: 100%;
      padding: 16px;
      &:focus-visible,
      &:active,
      &:hover {
        outline: none !important;
      }
    }

    img {
      width: 20px;
      object-fit: contain;
    }
  }
  &-input-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    padding: 16px;

    .form-check-input {
      width: 25px;
      height: 25px;
    }
  }
  .custom-button {
    display: block;
    min-width: 180px;
    margin: 0 auto;
  }
  .card-validate-selection-footer {
    padding-top: 10px;
    height: 100px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0px;
    transform: translateY(0);
    border-bottom: solid 1px #dfe5eb;
    background-color: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
