.pending-validation {
  padding-top: 20px;
  .pending-validation-title {
    font-weight: bold;
    font-size: 30px;
  }

  .pending-validation-subtitle {
    font-size: 18px;
    line-height: 20px;
  }
  .custom-button {
    margin-top: 100px;
  }
}
