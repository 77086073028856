@import 'styles/variables';
@import 'styles/mixins';

.footer {
  background-color: $primary-color;
  width: 100%;
}
.footer-container {
  max-width: 1240px;
  padding: 48px 20px;
  margin: 0 auto;

  @include for-size(phone-only) {
    padding: 20px;
  }

  .footer-logo-container {
    .footer-logo {
      width: 84px;
      height: 16px;
      object-fit: contain;
      margin-bottom: 30px;
    }
  }
  .footer-body {
    .footer-menu {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 30px;
      @include for-size(phone-only) {
        // flex-direction: column;
        // align-items: flex-start;
        // margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 20px;
        justify-content: space-between;
      }
    }
    .footer-body--item {
      display: flex;
      flex-direction: column;
      .footer-title {
        color: #ffffff;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.38px;
        line-height: 14px;
        margin-bottom: 15px;
      }
      .footer-custom-navlink {
        color: #ffffff;
        font-size: 14px;
        letter-spacing: -0.38px;
        line-height: 14px;
        margin-bottom: 12px;
        text-decoration: none;
      }

      .footer-play-store {
        height: 35.38px;
        width: 119.61px;
        margin-bottom: 12px;
      }
      .footer-apple-store {
        height: 39.74px;
        width: 119.66px;
      }
    }
    .footer-text {
      color: #ffffff;
      font-size: 10px;
      letter-spacing: 0;
      line-height: 10px;
      a {
        color: #ffffff;
        font-weight: bold;
      }
    }
  }
}
