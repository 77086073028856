@import 'styles/variables';
@import 'styles/mixins';

.offers-wrapper {
  min-height: calc(100vh - $navbar-minHeight);
  overflow-y: auto;
  padding-top: 45px;
  padding-bottom: 60px;

  .offers-header {
    position: relative;
    @include for-size(desktop-only) {
      // width: 75%;
      max-width: 1240px;
      padding: 0 20px;
      margin: 0 auto;
    }

    @include for-size(phone-only) {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }

    .Offers-header-title-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-size: 30px;
      .Offers-header-title-text {
        font-weight: bold;
        letter-spacing: -2.4px;
        line-height: 47px;
        text-align: center;
      }
      .Offers-header-title-btn {
        padding: 10px;
        border-radius: 10px;
        border: 1px solid $primary-color;
        cursor: pointer;
        margin-left: 10px;
        color: $primary-color;
        line-height: normal;
        user-select: none;
        font-size: 16px;
        font-weight: bold;
        &:active {
          opacity: 0.5;
        }
      }

      @include for-size(phone-only) {
        font-size: 18px;
        letter-spacing: -0.35px;
        .Offers-header-title-btn {
          padding: 4px;
          margin-left: 4px;
        }
      }
    }

    .offer-title {
      font-weight: 500;
      font-size: 24px;
      color: $primary-color;
    }
    .offers-header--search-container {
      width: 100%;
      position: relative;

      .offers-header--search {
        margin-bottom: 30px;
        margin-top: 30px;
      }
      .offers-header--search-close {
        width: 25px;
      }

      .offers-header--filter {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        padding-bottom: 32px;

        .offers-header--filter-list {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: nowrap;
          overflow-x: scroll;
          gap: 10px;
          padding-bottom: 15px;
          padding-left: 20px;
          padding-right: 20px;

          &::-webkit-scrollbar-track {
            display: none;
            border-radius: 10px;
            background-color: transparent;
          }
          &::-webkit-scrollbar {
            display: none;
            height: 6px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #f5f5f5;
          }
        }

        .offers-header--action1 {
          // height: 49px;
          // width: 49px;
          height: 42px;
          width: 42px;
          border: 1px solid #979797;
          background-color: #ffffff;
          margin-bottom: 16px;
          margin-right: 16px;
          border-radius: 50%;

          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;

          .offers-header--expand-icon {
            width: 22px;
            cursor: pointer;
          }
        }

        .offers-header--action1.expanded {
          background-color: $primary-color;
        }

        .offers-header--action2 {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 24px;
          height: 100%;
          padding-bottom: 16px;
          margin-left: 16px;

          @include for-size(phone-only) {
            display: none;
          }

          .offers-header--pre-icon,
          .offers-header--next-icon {
            width: 42px;
            height: 42px;
            cursor: pointer;
            &.active {
              opacity: 0.5;
              cursor: default;
            }
          }
        }

        .offers-header--filter-item {
          // height: 48px;
          min-width: 90px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          padding: 5px 20px;
          border: 1px solid #999999;
          background-color: white;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: -0.5px;

          cursor: pointer;

          border-radius: 30px;
        }
        .offers-header--filter-item.selected {
          border-color: $primary-color;
          background-color: $primary-color;
          color: white;
        }
      }

      .offers-header--expand-filter-container {
        // max-height: 350px;
        max-height: 590px;
        width: 100%;
        overflow-y: scroll;
        background-color: #ffffff;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        position: absolute;
        top: 150px;
        z-index: 99;

        &::-webkit-scrollbar {
          width: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #e5e5e5;
          border-radius: 100px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #ddd;
        }

        .offers-header--expand-filter-item {
          padding-bottom: 16px;
          padding-top: 16px;
          padding-left: 5px;
          font-weight: 500;
          border-bottom: 1px solid #d0d0d0;
          display: flex;
          align-items: center;

          cursor: pointer;

          &:last-child {
            border-bottom: none;
          }

          &--title {
            text-align: left;
            font-size: 22px;
            font-weight: bold;
            letter-spacing: -1.44px;
            line-height: 28px;
          }
          img {
            width: 30px;
            margin-right: 3px;
          }
        }
      }
    }

    &-btnBack {
      position: absolute;
      left: 0px;
      padding: 0;
      background-color: transparent;
      border: none;
      font-size: 20px;
      font-weight: bold;
      color: #3f52c7;
      top: -30px;
      & img {
        width: 18px;
        height: 18px;
        position: relative;
        bottom: 2px;
      }
    }
  }
  .title-container {
    // margin-top: 30px;
    // padding-left: 20px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title-container-with-img {
    margin-top: 30px;
    display: flex;
    flex-direction: row;

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
  .title {
    // font-size: 24px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -2;

    @include for-size(phone-only) {
      font-size: 25px;
    }
  }

  .icon {
    // width: 45px;
    // height: 45px;
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  .subtitle {
    // font-size: 24px;
    // font-size: 20px;
    font-size: 16px;
    letter-spacing: -1.44px;
    line-height: 28px;

    @include for-size(phone-only) {
      font-size: 18px;
    }
  }
  .offer-container {
    // display: flex;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    // overflow-x: scroll;
    // gap: 16px;
    // gap: 20px;
    gap: 62px;
    height: auto;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 60px;
    padding-top: 12px;
    padding-bottom: 12px;
    // padding-left: 20px;
    // padding-right: 20px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #e5e5e5;
      border-radius: 100px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #ddd;
    }
  }
  .color-text {
    color: $primary-color;
    font-weight: bold;
  }
  .offers-view-more-btn {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    & button {
      display: flex;
      background: transparent;
      align-items: center;
      justify-content: center;
      padding: 8px 10px 8px 10px;
      min-width: 140px;
      border: 1px solid #000000;
      border-radius: 20px;
      font-weight: bold;
    }
  }

  .offers-filtered {
    // display: flex;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    flex-wrap: wrap;
    // gap: 20px;
    gap: 62px;
    margin-top: 30px;
    margin-bottom: 30px;
    // padding-left: 20px;

    @include for-size(desktop-medium-sm) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  .premium-offers-container {
    background-color: #f4f6ff;

    .premium-offers-contents {
      padding-top: 32px;
      padding-bottom: 48px;

      .title-container {
        margin-top: 0;
      }

      @include for-size(desktop-only) {
        // width: 75%;
        max-width: 1240px;
        padding: 0px 20px;
        margin: 0 auto;
      }
    }
  }
  .standar-offers-container {
    @include for-size(desktop-only) {
      // width: 75%;
      max-width: 1240px;
      padding: 0px 20px;
      margin: 0 auto;
    }

    & .coming-soon {
      text-align: center;
      & h2 {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      & p {
        margin-bottom: 20px;
      }
      & .btn-all-offer {
        background-color: transparent;
        color: $primary-color;
      }
    }
  }
}
