@use 'styles/variables' as appVar;
@import 'styles/mixins';

.active-offer-item {
  height: 270px;
  width: 304px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  padding-top: 24px;
  padding-bottom: 24px;

  cursor: pointer;

  position: relative;

  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  .tick-icon {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 10px;
    top: 10px;
  }

  .offer-item-merchant {
    max-width: 40%;
    max-height: 60px;
    object-fit: contain;
  }

  .offer-item-info-container {
    width: 100%;
    padding-top: 10px;

    .offer-item-name {
      text-align: center;
      min-width: 60px;
    }
  }

  .offer-item-logo {
    height: 43.68px;
    width: 69.02px;
    object-fit: cover;
    border-radius: 4px;
    margin-top: 8px;
  }
  .icon-next {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }
  .name {
    flex: 1;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .reward {
    font-size: 14px;
    text-align: center;
  }
  .expired-time {
    font-size: 14px;
    text-align: center;
    color: appVar.$border-gray;
  }
}
