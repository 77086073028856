.autocomplete-dropdown-container {
  position: absolute;
  width: 100%;

  .autocomplete-dropdown-MenuList {
    .suggestion-item {
      padding-top: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid #ddd;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .css-4ljt47-MenuList {
    z-index: 100;
    background-color: #fff;
    position: relative;
    width: 96%;
    overflow-y: scroll;
    height: 110px;

    &::-webkit-scrollbar {
      height: 5px;
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #c5c5c5;
      border-radius: 100px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #ddd;
    }

    & .item-location {
      padding: 0.375rem 0.75rem;
      padding-left: 0;
      border-bottom: 1px solid #ddd;
    }
  }
}
