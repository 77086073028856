@import 'styles/mixins';

.premium-benefit-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  max-width: 650px;

  .premium-benefit-img {
    height: 159px;
    width: 193px;
    margin-right: 60px;

    @include for-size(phone-only) {
      margin-right: 15px;
      height: 120px;
      width: 160px;
      flex-basis: calc(50% - 15px);
    }
  }
  .premium-benefit-info-container {
    .title {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: -1px;
      line-height: 28px;
      margin-bottom: 8px;
    }
    .desc {
      font-size: 18px;
      letter-spacing: -0.75px;
      line-height: 28px;
    }

    @include for-size(phone-only) {
      flex-basis: 50%;
      .title {
        font-size: 18px;
        line-height: 20px;
      }

      .desc {
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
}

.premium-show-all {
  background-color: transparent;
  color: #3f52c7;
  font-size: 18px;
  font-weight: bold;
  min-width: 300px;
  border: none;
  position: absolute;
  right: 300px;
  top: 180px;
  img {
    transform: rotate(180deg);
    margin-left: 10px;
    width: 20px;
    height: 20px;
    transition: all 0.2s ease-out;
  }
  &.show {
    img {
      transform: rotate(270deg);
    }
  }
}
