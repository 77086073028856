@import 'styles/variables';

.modal-existing-card-mapping {
  max-width: 600px;
  padding-top: 30px;
  .modal-existing-card-mapping--title {
    font-weight: bold;
    font-size: 30px;
    letter-spacing: -1.25px;
    line-height: 36px;
    text-align: left;
    color: black;
    margin-bottom: 20px;
    text-align: center;
    margin-top: -20px;
  }

  &--subtitle {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }

  .modal-existing-card-mapping--text {
    font-size: 22px;
    line-height: 25px;

    span {
      font-weight: bold;
    }
  }

  .modal-existing-card-mapping--btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .modal-existing-card-mapping--btn {
    border: 2px solid $primary-color;
    min-width: 200px;
    height: 50px;
    background-color: white;
    border-radius: 30px;
    color: $primary-color;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
  }

  .modal-existing-card-mapping--btn.background {
    background-color: $primary-color;
    color: white;
  }

  &--card-container {
    padding: 16px;
    border: 1px solid $border-gray;
    border-radius: 10px;

    img {
      width: 100px;
    }
    div {
      font-size: 18px;
    }
  }
  &--card-status {
    color: $primary-color;

    img {
      width: 40px;
    }
  }
  &--card-image {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &--card-info {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .red-border {
    border: 1px solid red;
  }

  &--divider {
    height: 1px;
    width: 100%;
    background-color: $border-gray;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
