@use 'styles/variables' as appVar;
@import 'styles/mixins';

.saving-offer-item {
  min-height: 120px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 10px;
  padding-right: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;

  .saving-offer-item--col1 {
    display: flex;
    align-items: center;
  }

  .saving-offer-item--col2 {
    min-width: 100px;
    cursor: pointer;
    .saving-offer-item--end-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .saving-offer-item--amount {
        font-size: 20px;
        font-weight: bold;
      }
      margin-bottom: 16px;
    }
    .saving-offer-item--status {
      font-size: 16px;
    }
  }

  .saving-offer-checkbox {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }

  .saving-offer-merchant {
    width: 60px;
    object-fit: contain;
    border: 1px solid #9999;
    border-radius: 8px;
    margin-right: 15px;
    height: 60px;
    flex-shrink: 0;
    &.temp-logo {
      padding: 12px;
    }
  }

  .saving-offer-info-container {
    width: 100%;
    padding-top: 10px;

    .saving-offer-name {
      min-width: 60px;
    }
  }

  .icon-next {
    width: 20px;
    height: 16px;
    flex-shrink: 0;
    object-fit: contain;
  }
  .name {
    flex: 1;
    font-weight: bold;
    font-size: 16px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .reward {
    font-size: 14px;
  }
  .expired-time {
    font-size: 14px;
  }
}
