@import 'styles/variables';
@import 'styles/mixins';

.custom-navbar {
  background-color: white;
  min-height: $navbar-minHeight;
  border-bottom: solid 1px #dfe5eb;
  // padding-left: 20px;
  // padding-right: 20px;
  padding-top: 23px;
  padding-bottom: 23px;
  z-index: 999;

  .navbar-toggler {
    box-shadow: none;
    border-color: #00000026;
  }

  .navbar-toggler.expanded {
    .navbar-toggler-icon {
      background-image: $nav-icon-toggle-close;
    }
  }

  .custom-navlink {
    font-weight: bold;
    font-size: 24px;
    letter-spacing: -1.44px;
    line-height: 28px;
    color: black;
    text-decoration: none;
  }

  .custom-navlink.active {
    text-decoration: underline;
  }
  .dropdown {
    .dropdown-menu.show {
      transform: translate(0px, 60px) !important;
      border: none;
      box-shadow:
        0 3px 12px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 transparent;
    }
    .custom-button-dropdown-toggle {
      background-color: white;
      border-radius: 30px;
      background-color: white !important;
      padding: 8px 8px 8px 14px;
      border: 1px solid #ddd;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;

      &:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
      }
      .btn-user-container--menu {
        width: 16px;
        height: 16px;
      }
      .btn-user-container--user {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid #3f52c7;

        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    .dropdown-item {
      padding: 8px 18px;
      &:active {
        background-color: #f8f9fa;
        color: #000;
      }
    }
  }

  @include for-size(navbar-expand) {
    .custom-nav {
      margin: 0 auto;
      gap: 56px;
    }
  }

  @include for-size(navbar-collapsed) {
    .custom-nav {
      gap: 20px;
      margin-top: 30px;
    }
    .custom-button {
      margin-top: 30px;
    }
  }

  .container-fluid {
    max-width: 1440px;
    padding: 0 20px;
  }
}
