@import 'styles/variables';
@import 'styles/mixins';

.premium-wrapper {
  padding-bottom: 48px;
  min-height: 680px;
  .premium-header {
    width: 100%;
    min-height: 160px;
    background-color: $primary-color;
    padding-top: 32px;
    padding-bottom: 24px;
    padding-left: 20px;
    padding-right: 20px;

    .premium-header-img {
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
      // height: 26px;
      height: 30px;
    }
    .premium-header-des {
      font-size: 18px;
      letter-spacing: 0.25px;
      // line-height: 18px;
      line-height: normal;
      text-align: center;
      color: white;
      span {
        font-weight: bold;
      }
    }
  }

  .premium-manage-btn-container {
    max-width: 1240px;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .premium-manage-btn {
      color: $primary-color;
      font-size: 18px;
      text-align: right;
      padding: 20px;
      font-weight: bold;
      cursor: pointer;
      width: fit-content;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .premium-see-offers-card-container {
    max-width: 1240px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
  }
  .premium-see-offers-card {
    width: 400px;
    height: auto;
    padding: 30px;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    align-self: stretch;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    cursor: pointer;

    @include for-size(phone-only) {
      width: 100%;
    }

    .premium-see-offers-title {
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -1px;
      text-align: left;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .premium-see-offers-body {
      display: flex;
      align-items: center;

      img {
        width: 35px;
        flex-shrink: 0;
        margin-left: 10px;
      }

      .premium-see-offers-values-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;

        .premium-see-offers-value {
          .premium-see-offers-amount {
            font-size: 30px;
            font-weight: bold;

            @include for-size(phone-only) {
              font-size: 24px;
            }
          }
          .premium-see-offers-name {
            font-size: 24px;
            letter-spacing: -0.5px;

            @include for-size(phone-only) {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  .premium-body {
    .premium-active-offers-container {
      max-width: 1240px;
      margin: 0 auto;
      padding-top: 50px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 50px;
      .premium-active-offers-title {
        font-size: 30px;
        font-weight: bold;
        letter-spacing: -2.4px;
        line-height: normal;
        text-align: center;
        margin-bottom: 28px;
        // font-family: 'Sharp Grotesk Medium 25 Regular';

        @include for-size(phone-only) {
          font-size: 30px;
          line-height: 40px;
        }
      }
      .premium-active-offers-subtitle {
        font-size: 18px;
        letter-spacing: -0.75px;
        line-height: normal;
        text-align: center;
      }
      .premium-active-offers-list-container {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        position: relative;
        .ref-offer {
          text-align: right;
          width: 100%;
        }
        .premium-active-offers--action {
          padding-left: 20px;
          padding-right: 20px;
          position: absolute;
          border: none;
          height: 57px;
          width: 57px;
          background-color: #ffffff;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          cursor: pointer;
          img {
            width: 30px;
            height: 30px;
          }
          &.left {
            left: -33px;
            z-index: 999;
            top: calc(50% - 30px);
          }
          &.right {
            right: -33px;
            z-index: 999;
            top: calc(50% - 30px);
          }
          &.disabled {
            pointer-events: none;
            opacity: 0.5;
          }
        }
      }
      .premium-active-offers-list {
        // display: flex;
        margin-top: 60px;
        padding-top: 12px;
        padding-bottom: 12px;
        // overflow-x: scroll;
        flex-grow: 1;

        & .slick-dots {
          & li {
            & button::before {
              font-size: 10px;
            }
          }
        }
      }
    }
    .premium-active-offers-plus-icon-container {
      padding-top: 40px;
      padding-bottom: 40px;
      text-align: center;
      .plus-icon {
        width: 40px;
        height: 40px;
      }
    }

    .premium-active-non-offfers {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 60px;
      &-innerItem {
        display: flex;
        justify-content: center;

        & img {
          width: 500px;
        }

        &:nth-child(n + 1) {
          margin-bottom: 60px;
        }
      }

      &-details-btn {
        color: #3f52c7;
        background-color: transparent;
        text-decoration: underline;
        border: none;
      }
    }

    .premium-benefit-container {
      padding-top: 50px;
      max-width: 1240px;
      margin: 0 auto;
      padding-left: 20px;
      padding-right: 20px;

      @include for-size(phone-only) {
        padding-left: 20px;
        padding-right: 20px;
      }
      .premium-benefit--title {
        // font-size: 40px;
        // letter-spacing: -1.67px;
        // line-height: 51px;
        // text-align: center;
        // font-family: 'Sharp Grotesk Medium 25 Regular';
        // margin-bottom: 28px;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: -2.4px;
        line-height: normal;
        text-align: center;
        margin-bottom: 28px;

        @include for-size(phone-only) {
          font-size: 24px;
          line-height: 30px;
        }
      }
      .premium-benefit--subtitle {
        // font-size: 24px;
        // letter-spacing: -0.75px;
        // line-height: 28px;
        // text-align: center;

        font-size: 14px;
        letter-spacing: -0.75px;
        line-height: normal;
        text-align: center;
        margin-top: 60px;

        @include for-size(phone-only) {
          font-size: 18px;
          line-height: 20px;
        }
      }
      .premium-benefit-contents {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 48px;

        margin-bottom: 100px;
      }
    }
    .premium-digital-coupons-container {
      max-width: 1240px;
      margin: 0 auto;
      padding-top: 50px;
      padding-bottom: 70px;
      padding-left: 20px;
      padding-right: 20px;

      .premium-digital-coupons-title {
        font-size: 30px;
        font-weight: bold;
        letter-spacing: -2.4px;
        line-height: normal;
        text-align: center;
        margin-bottom: 28px;
        // font-family: 'Sharp Grotesk Medium 25 Regular';

        @include for-size(phone-only) {
          font-size: 30px;
          line-height: 40px;
        }
      }
      .premium-digital-coupons-subtitle {
        font-size: 18px;
        letter-spacing: -0.75px;
        // line-height: 24px;
        line-height: normal;
        text-align: center;
      }
      .premium-digital-coupons-list-container {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        position: relative;
        .ref-offer {
          text-align: right;
          width: 100%;
        }
        .premium-digital-coupons--action {
          padding-left: 20px;
          padding-right: 20px;
          position: absolute;
          border: none;
          height: 57px;
          width: 57px;
          background-color: #ffffff;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          cursor: pointer;
          img {
            width: 30px;
            height: 30px;
          }
          &.left {
            left: -33px;
            z-index: 999;
            top: calc(50% - 30px);
          }
          &.right {
            right: -33px;
            z-index: 999;
            top: calc(50% - 30px);
          }
          &.disabled {
            pointer-events: none;
            opacity: 0.5;
          }
        }
      }
      .premium-digital-coupons-list {
        // display: flex;
        margin-top: 60px;
        padding-top: 12px;
        padding-bottom: 12px;
        // overflow-x: scroll;
        flex-grow: 1;

        & .slick-dots {
          & li {
            & button::before {
              font-size: 10px;
            }
          }
        }
      }

      .premium-digital-coupons-item {
        padding: 20px 40px;
      }

      .premium-digital-coupons-innerItem {
        position: relative;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 37px 0 rgba(0, 0, 0, 0.15);
        align-items: center;
        justify-content: space-between;
        height: 300px;
        padding: 20px;
        border-radius: 15px;
        & h3 {
          margin-top: 35px;
          font-weight: bold;
        }
        & .premium-digital-coupons-img {
          width: 145px;
          height: auto;
        }

        & .premium-digital-coupons-chk {
          position: absolute;
          right: 20px;
          width: 35px;
          height: auto;
        }
      }
    }

    .premium-saving-steps-container {
      max-width: 1240px;
      margin: 0 auto;
      padding-top: 50px;
      padding-bottom: 70px;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .premium-saving-steps-title {
        font-size: 30px;
        font-weight: bold;
        letter-spacing: -2.4px;
        line-height: normal;
        text-align: center;
        margin-bottom: 45px;
      }
      & ol {
        counter-reset: step-counter;
        padding-right: 0;
        padding-left: 65px;
      }
      & li {
        counter-increment: step-counter;
        position: relative;
        margin-bottom: 40px;
        font-size: 22px;
        letter-spacing: -0.75px;
        &::marker {
          content: '';
        }
        &::before {
          content: counter(step-counter);
          width: 2.5rem;
          height: 2.5rem;
          text-align: center;
          font-size: 1.5rem;
          background-color: #3f52c7;
          border: 0.125rem solid #3f52c7;
          color: #fff;
          border-radius: 2rem;
          position: absolute;
          top: -5px;
          left: -60px;
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    .premium-comments {
      background-color: #f6f6f6;
    }

    .premium-comments-container {
      max-width: 1240px;
      margin: 0 auto;
      padding-top: 50px;
      padding-bottom: 70px;
      padding-left: 20px;
      padding-right: 20px;
      .premium-comments-title {
        font-size: 30px;
        font-weight: bold;
        letter-spacing: -2.4px;
        line-height: normal;
        text-align: center;
        margin-bottom: 28px;
        // font-family: 'Sharp Grotesk Medium 25 Regular';

        @include for-size(phone-only) {
          font-size: 30px;
          line-height: 40px;
        }
      }
      .premium-comments-list-container {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        position: relative;
        .ref-offer {
          text-align: right;
          width: 100%;
        }
        .premium-comments--action {
          padding-left: 20px;
          padding-right: 20px;
          position: absolute;
          border: none;
          height: 57px;
          width: 57px;
          background-color: #ffffff;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          cursor: pointer;
          img {
            width: 30px;
            height: 30px;
          }
          &.left {
            left: -33px;
            z-index: 999;
            top: calc(50% - 30px);
          }
          &.right {
            right: -33px;
            z-index: 999;
            top: calc(50% - 30px);
          }
          &.disabled {
            pointer-events: none;
            opacity: 0.5;
          }
        }
      }
      .premium-comments-list {
        // display: flex;
        margin-top: 30px;
        padding-top: 12px;
        padding-bottom: 12px;
        // overflow-x: scroll;
        flex-grow: 1;

        & .slick-dots {
          & li {
            & button::before {
              font-size: 10px;
            }
          }
        }
      }

      .premium-comments-item {
        padding: 20px 40px;
      }

      .premium-comments-innerItem {
        position: relative;
        display: flex;
        flex-direction: column;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
        min-height: 290px;
        padding: 35px 20px;
        border-radius: 15px;
        background-color: #ffffff;
        & h3 {
          margin-top: 35px;
        }
        .comment-user {
          align-items: center;
          gap: 20px;
          margin-bottom: 20px;
          &-initial {
            display: flex;
            background-color: $primary-color;
            color: #fff;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
            width: 40px;
            height: 40px;
            font-weight: bold;
            font-size: 20px;
          }

          &-info {
            .name {
              font-size: 20px;
              font-weight: bold;
              margin: 0px;
            }
            .date {
              font-size: 14px;
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
  .premium-btn {
    // height: 78px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 28px;
    padding-left: 40px;
    padding-right: 40px;
    min-width: 250px;

    &.inver {
      background-color: #fff;
      color: $primary-color;
    }
  }

  .premium-btn-activate-in-app {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    background-color: white;
    color: $primary-color;
    min-width: 300px;
  }
}

.plan-wrapper {
  margin-top: 20px;
  .plan-name {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    .discount-percentage {
      padding: 4px 10px;
      background-color: #ffd450;
      margin-left: 4px;
      border-radius: 20px;
      font-size: 10px;
      margin-top: 2px;
    }
  }
  .plan-original-price {
    font-size: 16px;
    font-weight: bold;
    text-decoration: line-through;
    color: grey;
    margin-right: 4px;
  }

  .discount-price {
    font-size: 16px;
    font-weight: bold;
    color: black;
  }
  .plan-unit {
    font-size: 16px;
    font-weight: 500;
    color: black;
  }
}
.card-number-input {
  border: 1px solid #dee2e6;
  padding: 10px;
  border-radius: 6px;
}

.card-cvv-input,
.card-expiry-input {
  border: 1px solid #dee2e6;
  padding: 10px;
  border-radius: 6px;
}

.subscribe-form {
  .subscribe-form-title {
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 28px;
    span {
      color: $primary-color;
    }
  }

  .subscribe-form-subtitle {
    font-size: 16px;
    letter-spacing: -0.67px;
    line-height: 19px;
  }
  .payment-method-title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
  }
  .submit-button {
    width: 100%;
    margin-top: 20px;
  }
  .payment-form-wrapper {
    margin-top: 20px;
    border: 1px solid grey;
    padding: 20px;
    border-radius: 8px;
  }
  input:focus {
    box-shadow: none;
    border-color: #dee2e6;
  }
}

.manage-subscription-container {
  margin-top: 30px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;

  .cancelled-text {
    color: #999999;
    font-size: 24px;
    letter-spacing: -1px;
    line-height: 28px;
  }

  .manage-subscription-body {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
  }

  .manage-subscription-title {
    font-size: 30px;
    letter-spacing: -1px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .manage-subscription-card {
    width: 400px;
    height: auto;
    padding: 30px;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    align-self: stretch;

    display: flex;
    flex-direction: column;

    .manage-subscription-card--body {
      flex-grow: 1;
      .manage-subscription-card--branch-container {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .manage-subscription-card--branch-logo {
        width: 40px;
        height: 35px;
        object-fit: contain;
        margin-right: 20px;
      }
      .manage-subscription-card--branch-info {
        font-size: 13px;
        letter-spacing: -0.37px;
        text-align: left;

        padding-top: 12px;
        padding-bottom: 12px;
      }
    }

    .manage-subscription-card--title {
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -1px;
      text-align: left;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .manage-subscription-card--btn {
      padding-left: 30px;
      padding-right: 30px;
      height: 34px;

      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 12px;
      letter-spacing: -0.6px;
      text-align: center;
      cursor: pointer;
      border: 1px solid #000000;
      border-radius: 28px;
    }
  }
}

.premium-highlight {
  color: $primary-color;
  cursor: pointer;
  sup {
    font-size: 20px;
    top: -0.1em;
  }
}
