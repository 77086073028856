.policy {
  padding-top: 30px;
  &-container {
    // max-width: 1240px;
    max-width: 900px;
    margin: 0 auto;
  }
}
.policy-frame {
  min-height: 18400px;
  border: none;
}
