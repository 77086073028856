@import 'styles/variables';

.search-input-container {
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;

  .search-input--input-container {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .search-loading-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .search-input--clear {
    width: 25px;
    cursor: pointer;
  }
  .search-icon {
    width: 24px;
    object-fit: contain;
    margin-right: 10px;
  }
  .search-input {
    width: 100%;
    padding: 16px;
    border: none;
    // font-size: 16px;
    font-size: 20px;
    border-radius: 0;
    background-clip: padding-box;
    line-height: 1.5;
    color: #495057;
    z-index: 9;
    text-align: left;

    &:focus-visible,
    &:active,
    &:hover {
      outline: none !important;
    }
  }
  .search-results {
    width: 100%;
    min-height: 200px;
    position: absolute;
    top: 60px;
    z-index: 999;
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: none;
    padding-bottom: 16px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;

    flex-grow: 1;
    overflow-y: auto;

    .search-result {
      font-size: 16px;
      letter-spacing: -0.5px;
      line-height: 40px;
      padding-left: 32px;

      cursor: pointer;
      strong {
        font-weight: bold !important;
      }
      &:hover {
        background-color: $primary-color;
        color: white;
      }
    }
    .no-result {
      font-size: 16px;
      letter-spacing: -0.5px;
      line-height: 40px;
      padding-left: 32px;
    }
    .no-result-link {
      font-size: 16px;
      letter-spacing: -0.5px;
      line-height: 40px;
      padding-left: 32px;
      text-decoration: underline;
      color: $primary-color;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      cursor: pointer;
    }
  }
}
