@import 'styles/variables';
@import 'styles/mixins';

.mapping-card-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 16px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;

  input {
    width: 25px;
    height: 25px;
  }

  img {
    width: 120px;
    height: 65px;
    object-fit: contain;
  }
  &--name {
    font-size: 18px;
  }
}
