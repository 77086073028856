.missed-reward-group {
  &-title {
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: bold;
  }
  &-item {
    border: 1px solid #e8e8e8;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 16px 42px 16px 16px;
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 62px;
  }

  &-infoBox {
    flex-grow: 1;
  }

  &-category {
    width: 50px;
  }

  &--rewards {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  &-nextIcon {
    display: flex;
    align-items: center;
    align-self: flex-end;
    & img {
      height: 20px;
      margin-left: 10px;
    }
  }
}
