@import 'styles/variables';

.modal-update-user {
  max-width: 950px;
  max-height: 500px;
}

.modal-update-user-info {
  &--title {
    font-size: 24px;
    font-weight: bold;

    span {
      color: $primary-color;
    }
  }

  &--text {
    font-size: 16px;
  }

  .form-label {
    font-size: 14px;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  input {
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #ddd;
    padding-left: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .red-highlight {
    border-color: $err-input;
  }

  .red-highlight-text {
    color: $err-input;
  }

  .red-highlight-address {
    input,
    label {
      border-color: $err-input;
    }
  }

  .phone-validate {
    width: 100%;
    &:focus-visible {
      outline: none;
    }
    &:focus {
      border-color: $primary-color;
    }
  }
  .form-control:focus {
    border-color: $primary-color;
  }
  .form-control.red-highlight:focus {
    border-color: $err-input;
  }

  .phone-validate.red-highlight {
    border-color: $err-input;
  }
}
