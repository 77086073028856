@import 'styles/variables';

.spent-by-category-chart {
  height: 300px;
  position: relative;

  .spent-by-category-chart-cols {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    height: 100%;
    width: 90%;
    position: absolute;
    z-index: 99;
  }
  .spent-by-category-chart-col-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    opacity: 0.7;
  }

  .no-selected {
    opacity: 1;
  }

  .spent-by-category-chart-col-container.active {
    opacity: 1;
  }

  .spent-by-category-chart-col {
    width: 20px;
    border-radius: 10px;
    background-color: $primary-color;
    height: 100%;
    cursor: pointer;
    position: relative;

    .spent-by-category-chart-amount {
      position: absolute;
      top: -10px;
      font-size: 14px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .spent-by-category-chart-label {
    font-size: 12px;
  }

  .line-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;

    .line-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      height: 14px;
      margin-top: -7px;

      &:last-child {
        margin-bottom: 10px;
        .line {
          border-style: double;
        }
      }
    }

    .line {
      width: 100%;
      height: 1px;
      border: 1px dashed $border-gray;

      &:last-child {
        border-style: double;
      }
    }
  }
}
