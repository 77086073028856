@import 'styles/mixins';
@import 'styles/variables';

.recommended-card-container {
  height: 148px;
  width: auto;
  min-width: 300px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 24px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include for-size(phone-only) {
    padding: 12px;
  }

  .recommended-card-survey-title {
    font-size: 20px;
    letter-spacing: -0.37px;
    font-weight: bold;
    span {
      color: $primary-color;
    }
  }

  .recommended-card-name-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .recommended-card-name-terms {
      text-decoration: underline;
      cursor: pointer;
      font-size: 12px;
    }
  }

  .recommended-card-name {
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 19px;
  }

  .recommended-card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .recommended-card-reward-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-basis: 80%;

      .recommended-card-reward {
        flex-shrink: 0;
        flex-grow: 1;
        &:nth-child(2) {
          border-left: 1px solid #cccccc;
          border-right: 1px solid #cccccc;
          padding-left: 20px;
          padding-right: 20px;

          @include for-size(phone-only) {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
        &:last-child {
          padding-left: 10px;
        }
      }

      .recommended-card-reward--title {
        font-size: 16px;
        letter-spacing: -0.37px;
      }
      .recommended-card-reward--value {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.5px;
        line-height: 23px;

        @include for-size(phone-only) {
          font-size: 18px;
        }
      }
      .recommended-card-specialOffer {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.5px;
        line-height: 23px;
      }
    }
  }
  .recommended-card-icon {
    width: 24px;
    height: 27px;
  }
}
