.goTopHidden {
  display: none;
}

.goTop {
  display: block;
  padding: 0.25rem;
  position: fixed;
  right: 0.8125rem;
  bottom: 0.8125em;
  background-color: #3f52c7 !important;
  border: 1px solid #fff;
  transform-origin: bottom right;
  transform: scale(1);
  width: 3.125rem;
  height: 3.125rem;
  box-sizing: border-box;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  box-shadow:
    0 0 4px #15151524,
    0 16px 20px -5px #1515152e;
  transition: background-color 0.2s ease;
}
