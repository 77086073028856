@import 'styles/variables';
@import 'styles/mixins';

.contactus {
  padding-top: 30px;
  padding-bottom: 60px;
  &-container {
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 20px;
  }
  &-header {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    & h1 {
      font-size: 30px;
      margin: 0px;
    }
  }

  &-btnBack {
    position: absolute;
    left: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: $primary-color;

    & img {
      width: 18px;
      height: 18px;
      position: relative;
      bottom: 2px;
    }
  }

  &-desc {
    font-size: 20px;
    margin-bottom: 24px;
    & .highlight {
      color: $primary-color;
      font-weight: bold;
    }
  }

  &-textarea {
    height: 201px;
    margin-bottom: 8px;
  }

  &-label {
    font-size: 18px;
    font-weight: 800;
    letter-spacing: -0.67px;
    line-height: 19px;
    margin-bottom: 16px;
  }

  &-count {
    font-size: 16px;
    color: #666666;
    letter-spacing: -0.5px;
    line-height: 14px;
    margin-bottom: 30px;
    &.maxed {
      color: $err-input;
    }
  }

  &-submitBox {
    display: flex;
    justify-content: center;
    & button {
      padding: 20px 100px;
    }
  }

  &-thankYou {
    display: flex;
    align-items: center;
    flex-direction: column;
    & h2 {
      font-weight: bold;
    }
    & .icon {
      width: 200px;
      height: 200px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    & button {
      padding: 20px 100px;
    }

    & p {
      margin-bottom: 48px;
      font-size: 18px;
      letter-spacing: -0.8px;
      line-height: 19px;
    }
  }
}
