.activated-offer-details-container {
  .activated-offer-details-top {
    display: flex;
    // align-items: center;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #dddd;

    img {
      // width: 44px;
      width: auto;
      height: 60px;
      margin-right: 16px;
    }

    .activated-offer-details-top--info {
      .activated-offer-details-top--info-name {
        // font-size: 16px;
        font-size: 20px;
        letter-spacing: -0.5px;
        font-weight: bold;
      }

      .activated-offer-details-top--info-des {
        font-size: 16px;
        letter-spacing: -0.37px;
        line-height: normal;
      }
    }
  }

  .activated-offer-details-card {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    background-color: #f4f4f4;
    padding: 10px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      width: 40px;
      height: 25px;
      margin-right: 16px;
      object-fit: contain;
    }

    div {
      font-size: 16px;
      letter-spacing: -0.38px;
      line-height: 14px;
    }
  }

  .activated-offer-details-date-left {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: -1.8px;
    line-height: 36px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .activated-offer-details-sub {
    font-size: 16px;
    letter-spacing: -0.5px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .activated-offer-details-description {
    font-size: 16px;
    letter-spacing: -0.5px;
    // line-height: 19px;
    line-height: 27px;

    &::-webkit-scrollbar {
      height: 5px;
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #c5c5c5;
      border-radius: 100px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #ddd;
    }

    &.hidden {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.show {
      overflow-y: scroll;
      height: 400px;
    }
  }

  .btn-readmore {
    border: none;
    padding: 0;
    background-color: transparent;
    color: #3f52c7;
    font-size: 16px;
    letter-spacing: -0.37px;
    margin-top: 5px;
    font-weight: bold;
    margin: 0 auto;
    display: block;
    margin-top: 30px;
    &.hidden {
      display: none;
    }
  }

  .active-check {
    display: block;
    margin-bottom: 10px;
    img {
      width: 30px;
      margin-right: 8px;
    }
  }
}
