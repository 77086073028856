@import 'styles/variables';
@import 'styles/mixins';

.profile {
  padding-top: 30px;
  padding-bottom: 60px;
  &-container {
    // min-height: calc(100vh - 97px);
    max-width: 1000px;
    margin: 0 auto;

    padding-left: 20px;
    padding-right: 20px;
  }

  &-infoBox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 33px;
    row-gap: 33px;
    margin-bottom: 30px;
  }

  &-header {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    & h1 {
      font-size: 30px;
      margin: 0px;
    }
  }

  &-btnBack {
    position: absolute;
    left: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: $primary-color;

    & img {
      width: 18px;
      height: 18px;
      position: relative;
      bottom: 2px;
    }
  }

  &-btnDelete {
    font-size: 20px;
    font-weight: bold;
    color: $primary-color;
    background-color: transparent;
    border: none;
    padding: 0px;

    &.active {
      display: none;
    }
  }

  &-label {
    font-size: 16px;
    line-height: 14px;
    color: #666666;
    // margin-bottom: 15px;
    margin-bottom: 10px;
  }

  &-value {
    padding: 10px 0px 10px 0px;
    font-size: 20px;
    line-height: 19px;
  }

  &-btnEdit {
    position: absolute;
    right: 0;
    background-color: transparent;
    padding: 10px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    color: $primary-color;
    line-height: normal;
    -webkit-user-select: none;
    user-select: none;
    font-size: 16px;
    font-weight: bold;
  }
  & span {
    color: $err-input;
  }

  &-submitBox {
    display: flex;
    justify-content: center;
  }

  & .custom-button {
    padding: 16px 40px;
  }

  & input {
    border: none;
    padding: 10px 0px 10px 0px;
    margin-bottom: 1rem;
    font-size: 20px;
    line-height: 19px;
    border-bottom: 2px solid #dfe5eb;
    border-radius: 0;
  }
}

.feedback {
  padding-top: 30px;
  padding-bottom: 60px;
  &-container {
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 20px;
  }
  &-header {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    & h1 {
      font-size: 30px;
      margin: 0px;
    }
  }

  &-btnBack {
    position: absolute;
    left: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: $primary-color;

    & img {
      width: 18px;
      height: 18px;
      position: relative;
      bottom: 2px;
    }
  }

  &-desc {
    font-size: 20px;
    margin-bottom: 24px;
    & .highlight {
      color: $primary-color;
      font-weight: bold;
    }
  }

  &-textarea {
    height: 201px;
    margin-bottom: 8px;
  }

  &-label {
    font-size: 18px;
    font-weight: 800;
    letter-spacing: -0.67px;
    line-height: 19px;
    margin-bottom: 16px;
  }

  &-count {
    font-size: 16px;
    color: #666666;
    letter-spacing: -0.5px;
    line-height: 14px;
    margin-bottom: 30px;
    &.maxed {
      color: $err-input;
    }
  }

  &-submitBox {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    & button {
      padding: 20px 100px;
    }
  }
}
