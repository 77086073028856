.modal-signup {
  font-family: 'Karla', sans-serif !important;
  .btn-close {
    border: none !important;
    background: none !important;
    position: absolute;
    z-index: 5000;
    right: 15px;
    top: 15px;
    font-size: 40px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: #000 !important;
  }
}

.modal-item {
  .custom-button {
    display: block;
    padding: 16px 80px;
    margin: 0px auto 30px;
  }
}

.modal-backdrop {
  --bs-backdrop-bg: rgb(0 0 0 / 0.2);
  --bs-backdrop-opacity: 1;
  backdrop-filter: blur(18px);
}
