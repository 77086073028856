@import 'styles/variables';
@import 'styles/mixins';

.cards-page-container {
  min-height: calc(100vh - 84px);

  .cards-page-body {
    // padding-top: 125px;
    // padding-top: 60px;
    position: relative;
    @include for-size(phone-only) {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 56px;
    }

    @include for-size(desktop-only) {
      // width: 75%;
      max-width: 1240px;
      padding-left: 20px;
      padding-right: 20px;
      margin: 0 auto;
    }

    .cards-page--title {
      font-size: 30px;
      font-weight: bold;
      letter-spacing: -2.4px;
      line-height: 47px;
      text-align: center;

      span {
        color: $primary-color;
      }
    }

    .cards-page-contents {
      padding-top: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      row-gap: 35px;
      flex-wrap: wrap;
      padding-bottom: 64px;

      .line-divider {
        width: 100%;
        max-width: 600px;
        height: 4px;
        // background-color: #999999;
        background-color: #d5d5d5;
        border-radius: 100px;
      }
    }

    .cards-page-divider {
      width: 100%;
      height: 1px;
      background-color: #999999;
      margin-bottom: 64px;
    }
  }
}

.cards-page {
  &-btnBack {
    position: absolute;
    top: 20px;
    left: 250px;
    padding: 0;
    background-color: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: $primary-color;

    & img {
      width: 18px;
      height: 18px;
      position: relative;
      bottom: 2px;
    }
  }

  &-btn-personal {
    position: absolute;
    top: 20px;
    right: 200px;
    padding: 6px 10px;
    background-color: transparent;
    border: 1px solid $primary-color;
    font-size: 20px;
    border-radius: 30px;
    color: $primary-color;
    cursor: pointer;
  }
}
