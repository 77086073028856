@import 'styles/variables';
@import 'styles/mixins';

.offer-details-page-container {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 10px;
  position: relative;
  min-height: 800px;

  .line-divider {
    height: 1px;
    width: 100%;
    background-color: #c5c5c5;
    margin-top: 12px;
    // margin-bottom: 12px;
    margin-bottom: 30px;
  }

  .offer-details-body {
    // max-height: calc(100vh - 100px - 40px - 80px);
    overflow-y: auto;
    padding: 24px;
    padding-top: 0px;
    padding-bottom: 180px;

    .offer-btn-outline {
      padding: 4px 32px;
      border: 1px solid black;
      border-radius: 30px;
      width: fit-content;
      margin: 0 auto;
      font-size: 14px;
      margin-top: 16px;
      cursor: pointer;
    }
    &::-webkit-scrollbar {
      height: 5px;
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #c5c5c5;
      border-radius: 100px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #ddd;
    }
    @include for-size(phone-only) {
      max-height: 440px;
    }

    .offer-details--merchant {
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-bottom: 16px;
      margin-bottom: 30px;
      img {
        width: 70px;
        height: 70px;
        margin-right: 16px;
        border: 1px solid #c5c5c5;
        border-radius: 10px;
        padding: 2px;
      }
      div {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  .offer-details-best-card-for {
    height: 24px;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 17px;
    background-color: #ffd551;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.37px;
    line-height: 19px;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
  }

  .offer-details-titleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .offer-details-btn-back {
      position: absolute;
      top: 12px;
      left: 0;
      padding: 0;
      background-color: transparent;
      border: none;
      font-size: 20px;
      font-weight: bold;
      color: #3f52c7;
      height: 40px;

      img {
        width: 18px;
        height: 18px;
        position: relative;
        bottom: 2px;
      }
    }
  }

  .offers-details-card-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin-bottom: 20px;

    .offer-card-img {
      width: 130px;
      height: 80px;
      border-radius: 8px;

      margin-right: 16px;
    }
    .offer-card-text {
      font-size: 18px;
      letter-spacing: -0.5px;
      font-weight: 500;
    }
  }
  .offer-reward {
    font-size: 16px;
    letter-spacing: -0.5px;
    margin-bottom: 16px;
    font-weight: 500;
    // &.fw-bold {
    //   color: $primary-color;
    // }
    span {
      color: $primary-color;
    }
  }
  .offer-reward-text {
    font-weight: bold;
    color: $primary-color;
  }
  .offer-details-save-more {
    height: 24px;
    width: fit-content;
    padding: 8px 12px 8px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(2, 2, 2, 0.1);

    font-size: 14px;
    letter-spacing: -0.37px;
    font-weight: bold;

    border-radius: 12px;

    margin-bottom: 10px;
  }

  .offer-details-rate-container {
    width: 100%;
    height: 'auto';
    background-color: #f4f6ff;
    border-radius: 8px;
    padding: 16px;

    .how-it-work-text {
      font-size: 14px;
      letter-spacing: -0.5px;
      text-align: left;
      margin-bottom: 10px;
      line-height: 18px;
      font-weight: 500;
    }

    .offer-details-rate-title {
      font-size: 16px;
      letter-spacing: -0.5px;
      text-align: left;
      margin-bottom: 10px;
      color: black;
      font-weight: 500;
      span {
        font-weight: bold;
        color: $primary-color;
      }
    }
    .offer-details-rate-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      font-size: 14px;
      .get-offer {
        text-decoration: underline;
        cursor: pointer;
        font-weight: 500;
      }
      .tc-apply {
        text-decoration: underline;
      }
      .btn-close-details {
        cursor: pointer;
      }
    }
    .offer-details-rate-tc {
      font-size: 12px;
      text-align: right;
      text-decoration: underline;
      width: 100%;
      text-align: right;

      span {
        cursor: pointer;
        width: fit-content;
      }
    }
  }
  .offer-details-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    width: 100%;

    .offer-details-footer-item {
      flex-basis: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      &:first-child {
        border-right: 1px solid $border-gray;
      }

      .offer-details-footer-icon {
        width: 24px;
        height: 24px;
        margin-bottom: 8px;
      }
      .offer-details-footer--text {
        font-size: 16px;
        letter-spacing: -0.67px;
        color: $primary-color;
      }
    }
  }
  .offer-details-premium {
    display: flex;
    height: 24px;
    width: fit-content;
    align-items: center;
    border-radius: 12px;
    padding: 4px;
    padding-right: 8px;
    margin-top: 20px;
    margin-bottom: 10px;

    background-color: $primary-color;
    .offer-details-premium-icon-container {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: 10px;

      .icon-check {
        width: 13px;
        height: 13px;
      }
    }
    .offer-details-premium-text {
      font-size: 14px;
      letter-spacing: -0.37px;
      color: white;
    }
  }

  .offer-details-footer-container {
    padding-top: 10px;
    height: 100px;
    width: 100%;

    position: fixed;
    top: calc(100vh - 100px);
    left: 0px;
    transform: translateY(0);
    border-bottom: solid 1px #dfe5eb;
    background-color: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    .custom-button {
      text-align: center;
      display: block;
      width: 150px;
    }
    .btn-get-offer,
    .btn-compare {
      min-width: 220px;
    }
    .btn-compare {
      background-color: white;
      color: $primary-color;
    }
  }

  .offer-details--no-offer-container {
    .offer-details--no-offer-content {
      background-color: #f4f6ff;
      border-radius: 8px;
      padding: 16px;
      width: 100%;
    }
    .offer-details--no-offer-btn {
      background-color: white;
      color: $primary-color;
      min-width: 220px;
      margin: 0 auto;
      display: block;
    }
  }
}

.Offer-coupon-container {
  width: 100%;
  height: auto;
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 16px;
  font-weight: 500;

  .Offer-coupon-description {
    font-size: 14px;
    letter-spacing: -0.37px;
  }
  .Offer-coupon-code {
    font-size: 14px;
    letter-spacing: -0.37px;
    span {
      font-weight: bold;
      color: $primary-color;
    }
  }
  .offer-coupon-btn-link {
    text-decoration: underline;
    font-size: 12px;
    margin-top: 16px;
    cursor: pointer;
  }
}

.offer-details-premium-container {
  width: 100%;
  height: 'auto';
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 16px;
  .best-card-bank-offer {
    padding: 0px;
  }

  .premium-offer-activated-tc {
    cursor: pointer;
    font-size: 12px;
    text-align: right;
    text-decoration: underline;
  }
}

.offer-bank-offer-title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.37px;
}

.premium-offer-activated-container {
  width: 100%;
  height: 'auto';
  background-color: #f4f6ff;
  border-radius: 8px;
  padding: 16px;

  margin-top: 10px;
  margin-bottom: 10px;

  .premium-offer-activated-card {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    img {
      width: 130px;
      height: 80px;
      border-radius: 8px;
      margin-right: 16px;
    }
    .premium-offer-activated-card-info {
      font-size: 18px;
      letter-spacing: -0.5px;
    }
  }

  .premium-offer-activated-lang {
    text-align: left;
    font-size: 14px;
    letter-spacing: -0.37px;
  }
  .premium-offer-activated-tc {
    cursor: pointer;
    font-size: 12px;
    text-align: right;
    text-decoration: underline;
  }
}

.custom-modal-offer-tc {
  .offerDescription-container {
    .active-check {
      display: block;
      margin-bottom: 10px;
      img {
        width: 30px;
        margin-right: 8px;
      }
    }

    .offerDescription--header {
      margin-bottom: 20px;
      .offerDescription--merchantName {
        font-size: 30px;
        text-align: center;
      }
    }

    .offerDescription--read-more {
      // text-decoration: underline;
      cursor: pointer;
      color: $primary-color;

      color: #3f52c7;
      font-size: 16px;
      letter-spacing: -0.37px;
      margin-top: 5px;
      font-weight: bold;
      margin: 0 auto;
      display: block;

      text-align: center;
      margin-top: 30px;
    }

    .no-read-more {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .read-more {
      height: 400px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        height: 5px;
        width: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #c5c5c5;
        border-radius: 100px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #ddd;
      }

      &.hidden {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.offer-details-save-more.tag-green {
  background-color: #0c8f38;
  color: white;
}

.offer-details-view-more {
  color: $primary-color;
  font-size: 14px;
  cursor: pointer;
  text-align: right;
  margin-top: 16px;
  font-weight: bold;
}

.custom-spinner-container {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 60%;
  z-index: 9999999999;
  overflow: hidden;
  p {
    font-weight: 700;
    text-align: center;
  }
}
