@import 'styles/variables';
@import 'styles/mixins';

.my-savings-container {
  max-width: 760px;
  // min-height: calc(100vh - 86px);

  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 60px;

  @include for-size(phone-only) {
    padding-top: 20px;
  }

  .my-savings-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 30px;
    margin-bottom: 30px;

    .my-savings-title-text {
      font-weight: bold;
    }
    .my-savings-title-btn {
      padding: 10px;
      border-radius: 10px;
      border: 1px solid $primary-color;
      cursor: pointer;
      margin-left: 10px;
      color: $primary-color;
      line-height: normal;
      user-select: none;
      font-size: 16px;
      font-weight: bold;
      &:active {
        opacity: 0.5;
      }
    }

    @include for-size(phone-only) {
      font-size: 18px;
      letter-spacing: -0.35px;
      padding-left: 20px;
      padding-right: 20px;
      .my-savings-title-btn {
        padding: 4px;
        margin-left: 4px;
      }
    }
  }

  .my-savings-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include for-size(phone-only) {
      padding-left: 20px;
      padding-right: 20px;
    }
    .my-savings-header--col1,
    .my-savings-header--col2 {
      .my-savings-select-container {
        width: 198px;

        @include for-size(phone-only) {
          width: 190px;
        }

        .my-savings-select__control {
          border-color: black;

          .my-savings-select__placeholder {
            color: black;
          }
        }
      }
    }
  }

  .my-savings-header--title {
    font-size: 16px;
    letter-spacing: -0.75px;
  }

  .my-savings-header--amount {
    font-size: 40px;
    font-weight: bold;
    letter-spacing: -1.25px;
    line-height: 47px;

    @include for-size(phone-only) {
      font-size: 30px;
      line-height: 40px;
    }
  }

  .my-savings-body {
    @include for-size(phone-only) {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }

    .my-savings-categories-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-top: 24px;

      @include for-size(phone-only) {
        padding-left: 10px;
        padding-right: 10px;
      }

      .my-savings--action1 {
        height: 42px;
        width: 42px;
        border: 1px solid #979797;
        background-color: #ffffff;
        margin-bottom: 16px;
        margin-right: 16px;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        .my-savings--expand-icon {
          width: 22px;
          cursor: pointer;
        }
      }

      .my-savings--action1.expanded {
        background-color: $primary-color;
      }
      .offers-header--action2 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        height: 100%;
        padding-bottom: 16px;
        margin-left: 16px;

        @include for-size(phone-only) {
          display: none;
        }

        .offers-header--pre-icon,
        .offers-header--next-icon {
          width: 42px;
          height: 42px;
          cursor: pointer;
          &.active {
            opacity: 0.5;
            cursor: default;
          }
        }
      }
    }

    .my-savings-categories {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      overflow-x: scroll;
      gap: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 20px;

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: transparent;
      }
      &::-webkit-scrollbar {
        display: none;
        height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #f5f5f5;
      }

      .my-savings-category {
        width: fit-content;
        // height: 48px;
        min-width: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        padding: 5px 20px;
        border: 1px solid #999999;
        background-color: white;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.5px;

        cursor: pointer;

        border-radius: 30px;
      }
      .my-savings-category.selected {
        border-color: $primary-color;
        background-color: $primary-color;
        color: white;
      }
    }
    .my-savings-categories--action {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      height: 100%;
      padding-bottom: 16px;
      margin-left: 16px;

      @include for-size(phone-only) {
        display: none;
      }

      .my-savings-categories--pre-icon,
      .my-savings-categories--next-icon {
        width: 48px;
        height: 48px;
        cursor: pointer;
      }
    }

    .my-savings-categories-wrapper {
      position: relative;

      .my-savings--expand-filter-container {
        max-height: 350px;
        width: 100%;
        overflow-y: scroll;
        background-color: #ffffff;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        position: absolute;
        top: 60px;
        z-index: 99;

        &::-webkit-scrollbar {
          height: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #e5e5e5;
          border-radius: 100px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #ddd;
        }

        .my-savings--expand-filter-item {
          padding-bottom: 16px;
          padding-top: 16px;
          border-bottom: 1px solid #d0d0d0;
          padding-left: 5px;
          display: flex;
          align-items: center;

          cursor: pointer;

          &:last-child {
            border-bottom: none;
          }

          &--title {
            text-align: left;
            // font-size: 22px;
            font-weight: 500;
          }
          img {
            width: 30px;
            margin-right: 10px;
          }
        }
      }
    }

    .my-savings-load-more-btn {
      cursor: pointer;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: -1.2px;
      line-height: 28px;
      color: $primary-color;

      @include for-size(phone-only) {
        font-size: 18px;
      }
    }
  }
  .my-savings-empty-list {
    .my-savings-empty-list--title {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 8px;
      margin-top: 20px;
    }

    .my-savings-empty-list--desc {
      font-size: 24px;
    }
  }

  .my-savings-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    max-height: 400px;
    overflow-y: scroll;
    padding-right: 10px;
    margin-top: 20px;
    padding-top: 10px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #e5e5e5;
      border-radius: 100px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #ddd;
    }

    .saving-offer-item {
      border-bottom: 1px solid #9999;

      // flex-basis: calc(50% - 16px);
    }

    @include for-size(phone-only) {
      padding-left: 10px;
      padding-right: 10px;

      .saving-offer-item {
        flex-basis: 100%;
      }
    }
  }

  .redeem-btn {
    min-width: 200px;
    margin: 0 auto;
    display: block;
    margin-top: 60px;
  }

  .redeem-link-btn {
    width: fit-content;
    color: $primary-color;
    font-size: 18px;
    margin: 0 auto;
    margin-top: 60px;
    // margin-top: 20px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.my-savings-btnBack {
  padding: 0;
  background-color: transparent;
  border: none;
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: bold;
  color: $primary-color;

  & img {
    width: 18px;
    height: 18px;
    position: relative;
    bottom: 2px;
  }
}
