@use 'styles/variables' as appVar;
@use 'styles/mixins' as mixins;

.modal-authen-methods {
  .title-container {
    margin-top: 32px;
    .title {
      font-weight: bold;
      font-size: 30px;
      letter-spacing: -1.25px;
      line-height: 36px;
      text-align: left;
      color: black;
    }
    .subTitle {
      font-size: 16px;
      letter-spacing: -0.67px;
      color: black;
      .text-link {
        text-decoration: underline;
        color: appVar.$primary-color;
        font-weight: bold;
        cursor: pointer;
      }
      .highlight {
        font-weight: bold;
      }
    }
  }

  .terms-conditions {
    font-family: Karla, sans-serif !important;
    font-size: 9px;
    letter-spacing: -0.5px;
    line-height: 14px;
    margin: 20px 0;

    .btn-text {
      color: appVar.$primary-color;
      cursor: pointer;
    }
  }

  .modal-btn-authen-methods-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 24px;
    margin-top: 60px;
    padding-bottom: 30px;

    .btn-social {
      width: 400px;
      height: 56px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      background-color: white;
      border: 1px solid black;
      border-radius: 30px;
      padding-left: 40px;
      padding-right: 40px;
      font-size: 22px;
      letter-spacing: -0.75px;
      font-weight: bold;
      cursor: pointer;

      .btn-title {
        flex: 1;
        font-size: 18px;
        text-align: center;
      }

      img {
        width: 24px;
      }

      @include mixins.for-size(phone-only) {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        font-size: 16px;

        img {
          width: 20px;
        }
      }
    }
  }
}
