.header-otp-step {
  .btn-back {
    width: fit-content;
    background-color: transparent;
    cursor: pointer;
  }
  .title {
    font-weight: bold;
    font-size: 30px;
    letter-spacing: -1.25px;
    line-height: 36px;
    text-align: left;
    color: black;
    margin-top: 24px;
  }
  .otp-code-title {
    margin-top: 0.5rem;
    font-size: 16px;
    letter-spacing: -0.67px;
    color: black;
  }
}
