@import 'styles/mixins';
@import 'styles/variables';

.activated-offer-item-container {
  width: 100%;
  height: auto;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  border-bottom: 1px solid #dddddd;

  .activated-offer-item-col1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .activated-offer-item-icon-star {
      width: 20px;
      height: 20px;
      margin-right: 13px;
      cursor: pointer;
    }

    .activated-offer-item-info-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      .activated-offer-item-info-offer-logo {
        width: 70px;
        height: 70px;
        object-fit: contain;
        margin-right: 24px;
        border-radius: 10px;
      }

      .activated-offer-item-info {
        margin: 10px 0px;
        .activated-offer-item-info--name {
          font-size: 16px;
          letter-spacing: -0.5px;
          font-weight: bold;
          line-height: 20px;
        }
        .activated-offer-item-info--lang,
        .activated-offer-item-info--date {
          font-size: 12px;
          letter-spacing: -0.37px;
          line-height: 13px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .activated-offer-item-info--view {
          color: $primary-color;
          font-size: 12px;
          letter-spacing: -0.37px;
          margin-top: 5px;
          font-weight: bold;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .activated-offer-item-col2 {
    flex-shrink: 0;
    width: 81px;
    text-align: right;
    .activated-offer-item-card-container {
      img {
        height: 38px;
        border-radius: 3px;

        &.insImg {
          // width: 52px;
          // object-fit: contain;
          // border-radius: 20px;
          width: 60px;
          object-fit: contain;
          border-radius: 17px;
        }
      }

      div {
        font-size: 12px;
        letter-spacing: -0.38px;
        line-height: 14px;
      }
    }

    .activated-offer-item-store-logo {
      // width: 52px;
      // height: 33px;
      height: 38px;
    }
  }
}
