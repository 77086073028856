@mixin for-size($size) {
  @if $size == phone-only {
    @media only screen and (max-width: 599px) {
      @content;
    }
  }
  @if $size == desktop-only {
    @media only screen and (min-width: 600px) {
      @content;
    }
  } @else if $size == navbar-collapsed {
    @media only screen and (max-width: 991px) {
      @content;
    }
  } @else if $size == navbar-expand {
    @media only screen and (min-width: 992px) {
      @content;
    }
  } @else if $size == desktop {
    @media only screen and (min-width: 1200px) {
      @content;
    }
  } @else if $size == desktop-medium {
    @media only screen and (min-width: 1400px) {
      @content;
    }
  } @else if $size == desktop-medium-sm {
    @media only screen and (max-width: 1240px) {
      @content;
    }
  } @else if $size == desktop-lg {
    @media only screen and (min-width: 1600px) {
      @content;
    }
  }
}
