@import 'styles/mixins';

.recommended-cards-container {
  padding-top: 32px;
  padding-bottom: 32px;

  @include for-size(phone-only) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  @include for-size(desktop-only) {
    // width: 100%;
    max-width: 1240px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
  }

  @include for-size(navbar-expand) {
    width: 70%;
    margin: 0 auto;
  }
  .recommended-cards-btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .recommended-cards-tag {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 40px;
      min-width: 255px;
      border-radius: 20px;
      background-color: #ffd551;
      padding-left: 30px;
      padding-right: 30px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.5px;
      line-height: 19px;
      text-align: center;

      @include for-size(phone-only) {
        max-width: 255px;
        width: auto;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 14px;
      }
    }
    .recommended-cards-btn-seeall {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 40px;
      width: 100px;
      border: 1px solid #000000;
      border-radius: 28px;

      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.8px;
      line-height: 19px;
      text-align: center;
      cursor: pointer;
    }
  }
  .recommended-cards-slider-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .recommended-cards-slider-wrapper {
      flex-grow: 1;
      width: calc(100% - (2 * 57px));
      padding-left: 30px;
      padding-right: 30px;

      @include for-size(phone-only) {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
      }
    }

    .recommended-cards-slider {
      padding-top: 10px;
      padding-bottom: 10px;

      .slick-list {
        padding: 0 !important;
        .slick-track {
          padding-top: 20px;
          padding-bottom: 20px;
        }
        .slick-slide {
          padding: 0 8px;
        }
      }
    }
  }

  .slider-custom-arrow {
    height: 57px;
    width: 57px;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;

    img {
      width: 30px;
      height: 27px;
    }

    @include for-size(phone-only) {
      display: none;
    }
  }
  .slider-custom-arrow.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
