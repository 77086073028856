@import 'styles/variables';
@import 'styles/mixins';

.notify {
  &.active {
    display: flex;
  }
  display: none;
  margin-bottom: 24px;
  padding: 15px 109px;
  border-radius: 12px;
  background-color: $primary-color;
  justify-content: space-between;
  align-items: center;
  &-text {
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 19px;
    color: #fff;
  }

  &-button {
    padding: 9px 15px;
    line-height: 14px;
    text-align: center;
    border: none;
    border-radius: 28px;
    margin-left: 10px;
    font-size: 12px;
    letter-spacing: -0.6px;
    font-weight: 600;
  }
}
