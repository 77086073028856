.progress-bar-wrapper {
  width: 100vw;
  padding: 15px 0px;
  padding-top: 0px;
  background-color: white;
  .progress-bar-text {
    margin-top: 15px;
    text-align: center;
  }
  .bar-wrapper {
    height: 6px;
    border-radius: 0px;
  }
}
