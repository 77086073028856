@import 'styles/variables';
@import 'styles/mixins';

.spent-by-category-transaction {
  &-container {
    justify-content: space-between;
    align-items: center;
    max-width: 800px;
    min-height: 600px;
    margin: 0 auto;
    padding: 0 20px;
    padding-top: 20px;
    margin-bottom: 60px;
    position: relative;

    .spent-by-category-transaction-title {
      font-size: 24px;
      font-weight: bold;
    }

    .btn-load-more {
      padding: 8px 12px;
      width: fit-content;
      border: 1px solid #000;
      border-radius: 30px;
      margin: 0 auto;
      margin-top: 30px;
      cursor: pointer;
    }

    .spent-by-category-transaction--category {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      div {
        &:first-child {
          display: flex;
          align-items: center;
          gap: 16px;

          img {
            width: 80px;
          }
        }
      }
      div {
        &:last-child {
          div {
            &:first-child {
              font-weight: bold;
              font-size: 20px;
            }
            &:last-child {
              font-size: 16px;
            }
          }
        }
      }
      .spent-by-category-transaction--name {
        font-weight: bold;
        font-size: 20px;
      }
    }
  }
  &-btnBack {
    padding: 0;
    background-color: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: $primary-color;
    margin-bottom: 20px;
    & img {
      width: 18px;
      height: 18px;
      position: relative;
      bottom: 2px;
    }
  }

  &-header-title {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: -2.4px;
    line-height: 47px;
    text-align: center;
  }

  &-content {
    & .infobox {
      & .transaction-item {
        margin-bottom: 20px;
        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
      }

      &-label {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
      }

      &-filter {
        display: flex;
        gap: 16px;
        align-items: flex-start;
        margin-bottom: 32px;
        & button {
          background-color: transparent;
          border: none;
          padding: 0px;
          font-weight: bold;
          color: #666666;
          &.active {
            color: #000;
            border-bottom: 4px solid #000;
          }
        }
      }
    }
  }
  &--text {
    font-size: 16px;
  }
}
