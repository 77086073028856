@import 'styles/variables';
@import 'styles/mixins';

.rewards-missed {
  &-container {
    padding-top: 20px;
  }
  &-flexbox {
    display: flex;
    // column-gap: 31px;
    // column-gap: 80px;
    column-gap: 100px;
    & .infobox {
      width: 55%;

      & .transaction-item {
        margin-bottom: 20px;
        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
      }

      &-label {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
      }

      &-filter {
        display: flex;
        gap: 16px;
        align-items: flex-start;
        margin-bottom: 32px;
        & button {
          background-color: transparent;
          border: none;
          padding: 0px;
          font-weight: bold;
          color: #666666;
          &.active {
            color: #000;
            border-bottom: 4px solid #000;
          }
        }
      }

      &-reward {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 24px;
        margin-bottom: 24px;
        & .title {
          display: flex;
          gap: 12px;
          align-items: flex-end;
          & img {
            width: 31px;
            height: 31px;
          }
          & h2 {
            margin: 0px;
            font-size: 18px;
          }
        }
        &-sm {
          display: flex;
          &-item {
            display: flex;
            flex-direction: column;
            width: 110px;
            &:first-child {
              padding-right: 12px;
            }
            &:last-child {
              padding-left: 12px;
              align-self: stretch;
              border-left: 1px solid lightgrey;
            }
          }

          &-title {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 10px;
          }

          &-price {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 0px;
            &.missed {
              font-weight: bold;
            }
          }
        }
      }
    }
    & .cardbox {
      width: 45%;
      & .missed-reward-item {
        margin-bottom: 8px;
      }

      &-title {
        & h2 {
          font-size: 24px;
          font-weight: bold;
        }
        margin-bottom: 16px;
      }
      @keyframes skeleton-loading {
        0% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0 50%;
        }
      }
      .missed-reward-loading {
        height: 170px;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.15) 37%, rgba(0, 0, 0, 0.06) 63%);
        background-size: 400% 100%;
        animation-name: skeleton-loading;
        animation-duration: 1.4s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        margin-bottom: 10px;
      }
    }
  }
  &--text {
    font-size: 16px;
  }
}

.modal-transaction-detail {
  padding-top: 20px;
  .modal-transaction-detail--title {
    font-size: 24px;
    font-weight: bold;
    span {
      color: $primary-color;
    }
  }
  .modal-transaction-detail--text {
    margin-top: 16px;
    font-size: 16px;
    & .txt-highlight {
      font-weight: bold;
    }
    & .top-msg {
      margin-bottom: 10px;
      display: block;
    }
  }
  .modal-transaction-detail--card {
    // width: 320px;
    margin-top: 24px;
    margin-bottom: 60px;
    .modal-transaction-detail--card-title {
      height: 30px;
      width: fit-content;
      background-color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 10px;
      padding-right: 10px;
      color: white;
      font-weight: bold;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .modal-transaction-detail--card-body {
      border: 1px solid #dddddd;
      border-radius: 12px;
      border-top-left-radius: 0;
      padding: 10px;
      .modal-transaction-detail--card-text {
        span {
          color: $primary-color;
          font-weight: bold;
        }
      }
      .modal-transaction-detail--card-icon {
        display: flex;
        align-items: center;
        margin-top: 20px;
        &.exist {
          margin-bottom: 20px;
        }
        .card-default {
          margin-right: 15px;
          height: 45px;
          width: 55.5px;
        }
        .card-image {
          height: 80px;
          margin-right: 15px;
          margin-left: 5px;
        }
        .card-info {
          font-size: 16px;
          line-height: 20px;
        }
        .modal-transaction-detail--card-text-link {
          color: $primary-color;
          font-size: 16px;
          line-height: 20px;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.recommended-detail {
  padding: 20px;
  background: #f7f6f6;
  margin-bottom: 24px;
  border-radius: 12px;
  .recommended-tag {
    height: 24px;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 17px;
    background-color: #ffd551;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.37px;
    line-height: 19px;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
  }

  .recommended-detail--card-icon {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .card-default {
      margin-right: 15px;
      height: 45px;
      width: 55.5px;
    }

    .recommended-detail--card-text-link {
      color: #3f52c7;
      font-size: 16px;
      line-height: 20px;
      cursor: pointer;
    }
  }
}
