@import 'styles/variables';
@import 'styles/mixins';

.home-container {
  .latest-offers-flexbox {
    .home-title {
      font-size: 24px;
      margin-bottom: 0;
    }

    .latest-offers-content {
      min-width: 200px;
      padding: 0 25px;
      @include for-size(desktop-medium-sm) {
        padding: 0 15px;
      }
    }
  }
}

.latest-offers-container {
  display: block;
  padding: 10px 0px;

  @include for-size(desktop-medium-sm) {
    max-width: 625px;
  }
  &::-webkit-scrollbar {
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e5e5e5;
    border-radius: 100px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #ddd;
  }

  .latest-offers-slider {
    .slick-list {
      padding: 0 !important;
      margin: 0 -25px;
      @include for-size(desktop-medium-sm) {
        margin: 0 -15px;
      }
      .slick-track {
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .slick-slide {
        padding: 0 25px;
        @include for-size(desktop-medium-sm) {
          padding: 0 15px;
        }
      }
    }
    .slick-dots li button::before {
      font-size: 10px;
    }
  }
}

.latest-offers-flexbox {
  display: flex;
  align-items: center;
  @include for-size(desktop-medium-sm) {
    justify-content: center;
  }
}

.home-nav-icon {
  position: relative;
  top: 50px;
  background-color: transparent;
  border: none;
  height: 30px;

  height: 57px;
  width: 57px;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  img {
    width: 30px;
    height: 27px;
  }

  & img {
    width: 30px;
    height: 30px;
  }
}

.home-nav-icon.disabled {
  pointer-events: none;
  opacity: 0.5;
}
