@import 'styles/variables';
@import 'styles/mixins';

.spent-by-category {
  &-container {
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    margin: 0 auto;
    margin-bottom: 60px;
    padding: 0 20px;
    padding-top: 20px;
    position: relative;
    .spent-by-category-filter--month {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 120px;

      margin: 0 auto;
      margin-bottom: 30px;

      img {
        width: 14px;
        cursor: pointer;
      }

      & .maxed {
        opacity: 0.4;
      }

      .spent-by-category-filter--month-value {
        font-size: 16px;
        letter-spacing: -0.5px;
        line-height: 19px;
      }
    }

    .chat-btn {
      position: absolute;
      right: 0;
      width: 38px;
      height: 37px;
      cursor: pointer;
    }

    .spent-by-category--title {
      font-size: 30px;
      font-weight: 800;
      letter-spacing: -0.94px;
      line-height: 36px;
      margin-bottom: 24px;
    }
    .spent-by-category--btn-more {
      border: 1px solid black;
      width: fit-content;
      margin: 0 auto;
      margin-bottom: 16px;
      padding: 8px 16px;
      border-radius: 30px;
      cursor: pointer;
    }
  }

  &-header {
    &-title {
      font-size: 30px;
      font-weight: 800;
      letter-spacing: -0.94px;
      line-height: 36px;
      margin-bottom: 24px;
    }
    &-highlight {
      color: $primary-color;
    }
    &-infoIcon {
      font-size: 20px;
      font-weight: 500;
      position: relative;
      bottom: 5px;
      left: 5px;
      cursor: pointer;
    }
  }

  &-filter {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    & button {
      width: 80px;
      border: 1px solid #d5d5d5;
      background-color: #fff;
      border-style: solid;
      padding: 2px 12px;
      font-size: 16px;
      letter-spacing: -0.5px;
      line-height: 19px;
    }
    &-left {
      border-radius: 100px 0px 0px 100px;
      &.active {
        color: #fff;
        background-color: #000000;
      }
    }
    &-right {
      border-radius: 0px 100px 100px 0px;
      &.active {
        color: #fff;
        background-color: #000000;
      }
    }
  }

  &-body {
    margin-bottom: 40px;
    min-height: 260px;
  }

  &-linkAcc {
    text-align: center;

    & button {
      font-size: 16px;
      color: #fff;
      border: none;
      padding: 16px 28.9px;
      background-color: #3f52c7;
      border-radius: 100px;
      font-weight: 500;
    }
  }

  &-wrapper {
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 16px;
  }

  &-btnBack {
    padding: 0;
    background-color: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
    color: $primary-color;
    margin-bottom: 20px;
    & img {
      width: 18px;
      height: 18px;
      position: relative;
      bottom: 2px;
    }
  }
}
