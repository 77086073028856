@import 'styles/variables';
@import 'styles/mixins';

.card-details-container {
  min-height: calc(100vh - 84px);

  .card-details-body {
    .card-details-title {
      height: 33px;
      width: -moz-fit-content;
      width: fit-content;
      border-radius: 17px;
      background-color: #0f8e38;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.5px;
      line-height: 19px;
      text-align: center;
      padding-left: 30px;
      padding-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 16px;
    }

    .card-details-info-container {
      // max-width: 700px;
      max-width: 900px;
      margin: 0 auto;
      padding-top: 64px;
      padding-bottom: 64px;

      padding-left: 20px;
      padding-right: 20px;
    }

    .card-details-desc {
      font-size: calc(1.2 * 1rem);
      line-height: 1.8;
      margin-top: 16px;
      margin-bottom: 40px;
      white-space: pre-wrap;
      color: #000;
      font-weight: 500;
      & .highlight {
        color: $primary-color;
      }
    }

    .card-details-btn {
      height: 55px;
      width: 300px;
      border-radius: 53px;
      // font-size: 24px;
      font-size: 20px;

      font-weight: bold;
      letter-spacing: -1.2px;
      line-height: 28px;
    }

    .card-details-chase {
      color: $primary-color;
      background-color: transparent;
      text-decoration: underline;
      border: none;
      height: 65px;
      width: 300px;
      border-radius: 53px;
      // font-size: 24px;
      font-size: 20px;

      font-weight: 900;
      letter-spacing: -1.2px;
      line-height: 28px;

      & .arrow {
        width: 20px;
        height: 20px;
      }
    }

    .card-details-cardContainer {
      background-color: #f5f5f5;
    }

    .card-details-contents {
      position: relative;
      max-width: 700px;
      padding-top: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 32px;

      .card-details-terms {
        margin-top: 32px;

        & a {
          text-decoration: none;
        }
      }

      @include for-size(navbar-expand) {
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;
      }

      @include for-size(phone-only) {
        padding-left: 20px;
        padding-right: 20px;
      }

      .featured-card {
        .featured-card-bonus {
          background-color: #3f52c7;
        }
      }
    }

    .card-details-btnViewAll {
      position: absolute;
      top: 20px;
      right: 10px;
      background-color: transparent;
      color: $primary-color;
      border: 1px solid $primary-color;
      border-radius: 10px;
      padding: 2px 10px;
    }

    .card-details-terms {
      font-size: 14px;
      letter-spacing: -0.96px;
      line-height: 19px;
      margin-top: 56px;
      & .popup {
        margin-top: 10px;
        color: rgb(10, 88, 202);
        cursor: pointer;
      }
      & a {
        text-decoration: none;
      }
    }
  }

  .card-details-footer {
    height: 92px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

    display: flex;
    justify-content: center;
    align-items: center;

    .card-details-footer-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;

      @include for-size(navbar-expand) {
        width: 75%;
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;
      }

      .card-details-footer-col {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;

        .card-details-footer--icon-container {
          background-color: $primary-color;
          height: 48px;
          width: 48px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &:last-child {
            background-color: black;
          }

          img {
            width: 21px;
            height: 21px;
          }
        }

        .card-details-footer--icon-black-container {
          cursor: pointer;

          img {
            width: 48px;
            height: 48px;
          }
        }
        .card-details-footer--icon-black-container.hidden {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
  }
}

.card-details-cta {
  display: flex;
  justify-content: center;
}

.sticky-section-animated {
  transition: transform 300ms ease-in-out;
  z-index: 1000;
  background: #fff;
  width: 100%;
  padding: 18px 0;
  min-height: 97px;
  & .card__title {
    margin-bottom: 0px;
    font-size: 20px;
    line-height: normal;
  }

  & .sticky-container {
    width: 900px;
    margin: 0 auto;
    padding: 0 20px;
  }

  &.active {
    position: fixed;
    top: 0;
    transform: translateY(0);
    border-bottom: solid 1px #dfe5eb;
  }

  &.inactive {
    position: fixed;
    top: 0;
    transform: translateY(-100%);
  }

  & .sticky-card-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: 0px;
  }

  .card-details-btn {
    height: 50px;
    border-radius: 53px;
    // font-size: 20px;
    font-size: 18px;

    font-weight: bold;
    letter-spacing: -1.2px;
    line-height: 28px;
    padding: 0px 30px;
  }

  .card-details-chase {
    color: $primary-color;
    background-color: transparent;
    text-decoration: underline;
    border: none;
    height: 65px;
    padding: 0px;
    border-radius: 53px;
    // font-size: 20px;
    font-size: 18px;

    font-weight: 900;
    letter-spacing: -1.2px;
    line-height: 28px;

    & .arrow {
      width: 20px;
      height: 20px;
    }
  }
}

.card-details-btnBack {
  position: absolute;
  left: 0px;
  padding: 0;
  background-color: transparent;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #3f52c7;
  top: 20px;
  left: -90px;
  & img {
    width: 18px;
    height: 18px;
    position: relative;
    bottom: 2px;
  }
}

.card-details-goDown .goDown {
  bottom: 3.8125em;
}
