@import 'styles/variables';
@import 'styles/mixins';

.compare-rewards-container {
  min-height: calc(100vh - 84px);
  padding-top: 20px;
  position: relative;
  @include for-size(desktop-only) {
    max-width: 60%;
    margin: 0 auto;
  }

  @include for-size(phone-only) {
    max-width: 100%;
  }

  .compare-rewards-header {
    margin: 0 auto;
    margin-bottom: 20px;
    max-width: 650px;
    padding-left: 20px;
    padding-right: 20px;

    .compare-rewards-header--merchant-container {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      margin-top: 10px;

      img {
        width: 100px;
        object-fit: contain;
        margin-right: 16px;
        border-radius: 10px;
        border: 1px solid #c5c5c5;
      }
      div {
        font-size: 16px;
      }
    }

    .compare-rewards-header--merchant-name {
      margin-bottom: 16px;
    }

    .compare-rewards-header--title {
      font-size: 40px;
      font-weight: bold;
      letter-spacing: -1.67px;
      line-height: 47px;
      text-align: left;
      margin-bottom: 30px;
      @include for-size(phone-only) {
        font-size: 30px;
        line-height: 32px;
      }
    }
    .compare-rewards-header--input {
      position: relative;
      input {
        font-size: 40px;
        font-weight: bold;
        letter-spacing: -2.4px;
        line-height: 47px;
        border: none;
        outline: none;
        color: black;
        width: 100%;
        border-radius: 0;
      }
      .compare-rewards-header--input-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #999999;
        img {
          width: 24px;
          height: 24px;
          cursor: pointer;
          object-fit: contain;
          flex-shrink: 0;
        }
      }
      .compare-rewards-header--input-lable {
        font-size: 12px;
        letter-spacing: -0.5px;
        line-height: 14px;
        margin-top: 8px;
        text-align: left;
      }
    }
  }

  .compare-rewards-body {
    .compare-rewards-contents {
      // width: fit-content;
      max-width: 650px;
      margin: 0 auto;
      padding-left: 20px;
      padding-right: 20px;

      @include for-size(phone-only) {
        width: 100%;
      }
    }
  }

  .tag {
    height: 18px;
    width: fit-content;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 9px;
    background-color: #dddddd;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.38px;
    line-height: 14px;
    text-align: center;
    margin-bottom: 16px;
  }
  .tag-yellow {
    background-color: #ffd551;
  }
}

.compare-card-container {
  height: auto;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  background-color: #ffffff;
  margin-bottom: 16px;

  @include for-size(phone-only) {
    width: 100%;
  }

  .compare-card-info {
    padding-top: 12px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
  }

  .compare-card-title {
    font-size: 12px;
    letter-spacing: -0.38px;
    line-height: 14px;
    margin-bottom: 16px;
  }
  .compare-card-info-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      height: 47px;
      width: 75px;
    }
    .compare-card-info-text {
      font-size: 12px;
      letter-spacing: -0.37px;
    }

    .compare-card-info-reward {
      font-size: 16px;
      letter-spacing: -0.5px;
      font-weight: bold;
      text-align: right;
    }
  }

  .compare-card-offers-container {
    background-color: #f4f6ff;
    padding-top: 12px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    .compare-card-offers-tag {
      height: 18px;
      width: fit-content;
      padding-left: 12px;
      padding-right: 12px;
      border-radius: 9px;
      background-color: rgba(2, 2, 2, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: -0.38px;
      line-height: 14px;
      text-align: center;
      margin-bottom: 12px;
    }

    .compare-card-offers-rewards {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      .compare-card-offers-rewards-desc {
        font-size: 16px;
        letter-spacing: -0.5px;
        line-height: 19px;
        text-align: left;
      }
      .compare-card-offers-rewards-amount {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.96px;
        line-height: 19px;
        text-align: right;
      }
    }
    .devider {
      height: 1px;
      width: 100%;
      opacity: 0.1;
      background-color: #000000;
      margin-top: 16px;
      margin-bottom: 16px;
    }
    .compare-card-offers-btn {
      height: 24px;
      width: 80px;
      border: 1px solid #000000;
      border-radius: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: -0.6px;
      line-height: 14px;
      text-align: center;
      cursor: pointer;
    }

    .compare-card-premium-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .compare-card-premium-amount {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.96px;
        line-height: 19px;
        text-align: right;
      }
      .compare-card-bank-offer-desc {
        font-size: 16px;
        letter-spacing: -0.5px;
        line-height: 19px;
      }
    }
    .compare-card-bank-offer-error {
      font-size: 16px;
      color: red;
    }
    .compare-card-premium {
      background-color: $primary-color;
      padding-left: 2px;
      height: 18px;
      width: 164px;
      border-radius: 9px;
      background-color: #3f52c7;
      display: flex;
      align-items: center;
      img {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin-right: 8px;
      }
      .compare-card-premium-text {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: -0.38px;
        line-height: 14px;
        text-align: center;
        color: #ffffff;
      }
    }

    .compare-card-offers--how-it-work {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text-btn {
        text-decoration: underline;
        cursor: pointer;
      }
      .btn-close-details {
        cursor: pointer;
      }
    }
  }

  .compare-card-offers-container.bg-white {
    background-color: #ffffff;
  }
}

.compare-rewards-btnBack {
  padding: 0;
  background-color: transparent;
  border: none;
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: bold;
  color: #3f52c7;
  position: absolute;
  top: 24px;
  & img {
    width: 18px;
    height: 18px;
    position: relative;
    bottom: 2px;
  }
}

.compare-item {
  margin-bottom: 30px;
  .compare-card--total-savings {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    img {
      width: 30px;
      margin-right: 10px;
    }
    div {
      font-weight: bold;
      font-size: 16px;
    }
  }
  .compare-card--get-offer-btn {
    height: 40px;
    padding: 8px 18px;
    display: block;
    margin: 0 auto;
    margin-top: 20px;
  }
  .compare-card--tc-btn {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 16px;
  }
}

.compare-suggestion {
  border: solid 2px #dfe5eb;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 30px;
  &-topModal {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    & .tag {
      background-color: #f2aa84;
      height: 33px;
      width: -moz-fit-content;
      width: fit-content;
      border-radius: 17px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.5px;
      line-height: 19px;
      text-align: center;
      padding-left: 30px;
      padding-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0px;
    }

    & .close {
      cursor: pointer;
      border: none !important;
      background: none !important;
      z-index: 5000;
      right: 15px;
      font-size: 40px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      color: #000 !important;
    }
  }

  &-bodyModal {
    h3 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
      span {
        color: $primary-color;
      }
    }

    .desc {
      font-size: calc(1.2 * 1rem);
      margin-bottom: 30px;
    }

    .custom-button {
      padding: 16px 80px;
      margin: 0 auto;
      display: block;
    }
  }

  &.active {
    display: block;
  }

  &.inactive {
    display: none;
  }
}
