@import 'styles/variables';
@import 'styles/mixins';

.card-validation {
  max-width: 700px;
  margin: 0 auto;
  padding-top: 10px;
  position: relative;
  min-height: 800px;
  padding-left: 20px;
  padding-right: 20px;

  &-title {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.94px;
    line-height: 36px;
    margin-bottom: 16px;
  }
  &-subtitle {
    font-size: 18px;
    letter-spacing: -0.94px;
    line-height: 25px;
    margin-bottom: 16px;
  }
}
