.term {
  &-container {
    // max-width: 1240px;
    max-width: 900px;
    margin: 0 auto;
  }

  &-frame {
    min-height: 17400px;
    border: none;
  }
}
