.otp-wrapper {
  div {
    gap: 8px;
  }
  .otp-input {
    height: 54px;
    width: 54px !important;
    border: none;
    border-bottom: 1px solid #999999;
    font-size: 24px;
    font-weight: bold;
    &:focus,
    &:active {
      outline: none;
    }
  }
}
