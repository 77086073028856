.modal-add-merchant-contact {
  padding-top: 20px;
  &--title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  &--text {
    font-size: 16px;
  }

  &--img {
    width: 100%;
    height: 400px;
  }
  input {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &--icon {
    display: block;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100px;
  }
}
