@use 'variables' as appVar;

.err-input {
  color: appVar.$err-input;
  font-size: 12px;
}

.cursor-pointer {
  cursor: pointer;
}
