@import 'styles/variables.scss';

.custom-button {
  font-family: 'Karla', sans-serif !important;
  background: $primary-color;
  color: #fff;
  border: 1px solid $primary-color;
  border-radius: 100px;
  min-width: 95px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.8px;
  line-height: 19px;
  text-align: center;
  padding: 11px 23px;

  &:disabled {
    opacity: 0.4;
  }
}

.cancel-button {
  font-family: 'Karla', sans-serif !important;
  background: white !important;
  color: $primary-color !important;
  border: 1px solid $primary-color;
  border-radius: 100px;
  min-width: 95px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.8px;
  line-height: 19px;
  text-align: center;
  padding: 11px 23px;
  margin-left: 20px;

  &:disabled {
    opacity: 0.4;
  }
}
