.missed-reward {
  &-title {
    margin-bottom: 0px;
    // font-size: 24px;
    font-size: 18px;
    font-weight: 400;
  }
  &-item {
    position: relative;
    box-sizing: border-box;
    border: 1px solid #e8e8e8;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 16px 42px 16px 16px;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    gap: 62px;
  }

  &-image {
    & img {
      height: 50px;
      width: auto;
    }
  }

  &-infoBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-sm {
    display: flex;
    &-item {
      display: flex;
      flex-direction: column;
      width: 110px;
      &:first-child {
        padding-right: 12px;
      }
      &:last-child {
        padding-left: 12px;
        align-self: stretch;
        border-left: 1px solid lightgrey;
      }
    }

    &-title {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 10px;
    }

    &-price {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0px;
    }
  }

  &-nextIcon {
    position: absolute;
    right: 15px;
    bottom: 20px;
    & img {
      height: 20px;
    }
  }
}
